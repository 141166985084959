<div class="sidebar">
  <div class="modal-header">
    <div class="modal-header__title">Agent Feedback</div>
    <div class="modal-close inline-svg" [inlineSVG]="'#icon-close'" (click)="closeSidebar()"></div>
  </div>
  <div class="modal-body">
    <ng-container>
      <div *ngFor="let kudos of (rows$ | async)">
        <div class="kudos">
          <div class="kudos__avatar">
            <avatar-placeholder class="size-40"
                              [name]="kudos.name"
                              [image]="kudos.avatar"></avatar-placeholder>
          </div>
          <div class="kudos__info">
            <div class="kudos__info--justify">
              <p class="kudos__name">{{ kudos.name }}</p>
              <p class="kudos__date">{{ kudos.created_at | date }}</p>
            </div>
            <div>
              <p class="kudos__label">KUDOS</p>
              <p class="kudos__message">
                <span>{{kudos.text}}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div #ghost class="text-center" [hidden]="(rows$ | async)?.length > 1|| (pagination$ | async)?.total_objects === (rows$ | async)?.length">
        <loading-dots class="loading-dots--lg p-t-40 p-b-40 visible"></loading-dots>
      </div>
    </ng-container>
  </div>
  <div class="modal-footer"></div>
</div>
