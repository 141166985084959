<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title" *ngIf="modalData?.title">{{ modalData?.title }}</h4>
    <div class="close inline-svg" aria-label="Close" [inlineSVG]="'#icon-close'" (click)="closeModal()"></div>
  </div>
  <div class="modal-body">
    <div class="modal-body__title" *ngIf="modalData?.subtitle" [innerHTML]="modalData?.subtitle | newline"></div>
    <div class="modal-body__text text-left">
      <p>We understand that you're considering ending your partnership with the realtor on the myhomeIQ platform. Before you proceed, it’s crucial to be aware of the implications this decision has, both for you and the agent.</p>
      <h2>Impact on the Agent's Premium Privileges</h2>
      <ol>
        <li>Loss of Premium Features: Once you disconnect, the agent will lose access to premium privileges in their myhomeIQ account. This includes advanced marketing tools, in-depth analytics, and other premium functionalities.</li>
        <li>Notification to the Agent: They will receive an email informing them of the loss of these privileges and the disconnection from your partnership.</li>  
      </ol>
      <h2>Compliance with RESPA</h2>
      <p>As per the Real Estate Settlement Procedures Act (RESPA) compliance, we are obligated to offer the agent the option to select a new lender of their choice. This ensures transparency and fairness in business practices.</p>
      <h2>Potential Impact on Ongoing Marketing Campaigns</h2>
      <p>Many agents actively engage in marketing campaigns on social media and other platforms. Losing access to premium features could significantly impact these efforts, potentially affecting their ongoing business and lead generation activities.</p>
      <h2>Recommended Course of Action</h2>
      <p>Notify the Agent: We strongly encourage you to communicate directly with the agent about this impending change. This notification allows them to prepare and make necessary adjustments to their marketing strategies and client communications.</p>
      <p>We urge you to consider these factors carefully before making your final decision. Maintaining strong, collaborative relationships on our platform is key to the mutual success of lenders and agents alike.</p> 
      <p>If you decide to proceed with the disconnection or have any questions, please feel free to contact us for support.</p>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row row-fix">
      <div class="col col-fix" *ngIf="modalData?.actions.close.is_show" [ngClass]="modalData?.actions.close.class">
        <iq-button class="block"
                   label="{{ modalData?.actions?.close?.label }}"
                   [color]="modalData?.actions.close.color"
                   [style]="modalData?.actions.close.style"
                   (onClick)="confirm()"></iq-button>
      </div>
      <div class="col col-fix" *ngIf="modalData?.actions.dismiss.is_show" [ngClass]="modalData?.actions.dismiss.class">
        <iq-button class="block"
                   label="{{ modalData?.actions?.dismiss?.label }}"
                   [color]="modalData?.actions.dismiss.color"
                   [style]="modalData?.actions.dismiss.style"
                   (onClick)="closeModal()"></iq-button>
      </div>
    </div>
  </div>
</div>
