import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges,
} from '@angular/core';
import { debounceTime, filter, map } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TypedFormGroup } from '@core/types/form-group-config.type';

@UntilDestroy()
@Component({
  selector: 'iq-simple-search-filter',
  templateUrl: './iq-simple-search-filter.component.html',
  styleUrls: ['./iq-simple-search-filter.component.scss'],
})
export class IqSimpleSearchFilterComponent implements OnInit, OnChanges {

  @Input() stateValue: string;

  @Output() emitFilterValue: EventEmitter<{ filterName: string; value: any }> = new EventEmitter();

  form: FormGroup = this.fb.group<TypedFormGroup<{ query: string }>>({ query: [''] });

  constructor(
    private fb: FormBuilder,
  ) {
  }

  ngOnInit(): void {
    this
      .form
      .valueChanges
      .pipe(
        map((event: any) => event.query),
        filter((value: string) => value?.length > 2 || value?.length === 0),
        debounceTime(500),
        untilDestroyed(this),
      )
      .subscribe((query: string) => {
        this.search(query);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { stateValue } = changes;

    if (stateValue) {
      this.form.get('query').patchValue(stateValue?.currentValue || '');
    }
  }

  search(value: string): void {
    this.emitFilterValue.emit({ value: value ? value.trim() : '', filterName: 'search' });
  }

}
