import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Component, Inject, OnInit } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { distinctUntilChanged } from 'rxjs/operators';

import { Dictionary, isPermitted } from 'asap-team/asap-tools';
import { Profile } from '@core/types';

// Consts
import { environment } from 'environments/environment';
import {
  ROUTE,
  USER_ROLE,
  BILLING_PLAN,
  PERMITTED_ACTION,
} from '@consts/consts';

// Services
import { UserService } from '@core/services/user/user.service';

// Components
import { YoutubeVideoComponent } from '@commons/modals/youtube-video/youtube-video.component';
import { GetInviteLinkComponent } from '@commons/modals/get-invite-link/get-invite-link.component';
import { isEqual } from 'lodash-es';
import { IqModalService } from '@commons/iq-modal/iq-modal.service';

@UntilDestroy()
@Component({
  selector: 'traffic-collection-placeholder',
  templateUrl: 'traffic-collection-placeholder.component.html',
  styleUrls: ['traffic-collection-placeholder.component.scss'],
})
export class TrafficCollectionPlaceholderComponent implements OnInit {

  USER_ROLE: any = USER_ROLE;

  BILLING_PLAN: any = BILLING_PLAN;

  ROUTE: any = ROUTE;

  copy: Dictionary;

  profile: Profile;

  public readonly PERMITTED_ACTION: Dictionary = PERMITTED_ACTION;

  constructor(
    @Inject(WINDOW) private window: Window,
    private userService: UserService,
    private iqModalService: IqModalService,
  ) {}

  ngOnInit(): void {
    this.userService.profile$
      .pipe(
        distinctUntilChanged(isEqual),
        untilDestroyed(this),
      ).subscribe((profile: Profile) => {
        this.profile = profile;
      });
  }

  get isAgentTrial(): boolean {
    return this.profile?.role === USER_ROLE.agent && this.profile?.plan_name === BILLING_PLAN.TRIAL;
  }

  get isAgentSoloPlan(): boolean {
    return this.profile?.role === USER_ROLE.agent && this.profile?.plan_name === BILLING_PLAN.SOLO_PLAN;
  }

  get isAgentConnected(): boolean {
    return this.profile?.role === USER_ROLE.agent && this.profile?.plan_name === BILLING_PLAN.CONNECTED_PLAN;
  }

  get isLenderSubIsPaused(): boolean {
    return isPermitted(this.profile?.permitted_actions, PERMITTED_ACTION.HOMEOWNER_LEADS_LOCK_SUBSCRIPTION_RENEW);
  }

  get isContactSupportState(): boolean {
    // eslint-disable-next-line max-len
    return isPermitted(this.profile?.permitted_actions, PERMITTED_ACTION.HOMEOWNER_LEADS_STATE_PROMO) && !this.profile?.apps?.seller_traffic?.enabled;
  }

  get lenderLendingUrl(): string {
    return `${environment.herUrl}/${this.profile.username}`;
  }

  showGetLinkModal(): void {
    this.iqModalService.open(GetInviteLinkComponent);
  }

  goToHomeValueLanding(): void {
    this.window.open(this.lenderLendingUrl, '_blank').focus();
  }

  goToHomeWealthLanding(): void {
    this.window.open(`${this.lenderLendingUrl}/home-value`, '_blank').focus();
  }

  showVideoModal(): void {
    this.iqModalService.open(YoutubeVideoComponent, { id: 'https://www.youtube.com/embed/RL4UNLc3Pgg' });
  }

}
