import { Component, Input } from '@angular/core';
import { Dictionary } from 'asap-team/asap-tools';

// Consts
import { COLLECTION_PLACEHOLDER_COPY, PERMITTED_ACTION } from '@consts/consts';

// Components
import { VideoComponent } from '@commons/modals/video/video.component';
import { IqModalService } from '@commons/iq-modal/iq-modal.service';

@Component({
  selector: 'collection-placeholder',
  templateUrl: './collection-placeholder.component.html',
  styleUrls: ['./collection-placeholder.component.scss'],
})
export class CollectionPlaceholderComponent {

  @Input() videoID: string = null;

  @Input() isEmpty: boolean = null;

  PERMITTED_ACTION: any = PERMITTED_ACTION;

  constructor(
    private iqModalService: IqModalService,
  ) {}

  get copy(): Dictionary {
    return COLLECTION_PLACEHOLDER_COPY[this.isEmpty ? 'collection_empty' : 'not_subscribed'];
  }

  showVideo(): void {
    this.iqModalService.open(VideoComponent, { id: this.videoID });
  }

}
