import { formatCardExpiry } from 'payment';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormErrors, toDashCase } from 'asap-team/asap-tools';

import type { APIError, APIErrorResponse, PaymentMethod } from '@core/types';

// Consts
import { updateCardErrors } from '@consts/consts';

// Validators
import { cardExpiryValidator } from '@core/validators/card-expiry.validator';

// Services
import { BillingService } from '@core/services/billing/billing.service';
import { DialogRef } from '@angular/cdk/dialog';
import { TypedFormGroup } from '@core/types/form-group-config.type';

@UntilDestroy()
@Component({
  templateUrl: './update-card.component.html',
  styleUrls: ['./update-card.component.scss'],
})
export class UpdateCardComponent implements OnInit {

  @ViewChild('update_card_date') update_card_date: ElementRef;

  card: PaymentMethod;

  form: FormGroup;

  errors: APIError<any>[];

  action: Subscription;

  formErrors: FormErrors = updateCardErrors;

  constructor(
    private builder: FormBuilder,
    private billingService: BillingService,
    private dialogRef: DialogRef,
  ) {
  }

  ngOnInit(): void {
    this.form = this.builder.group<TypedFormGroup<{ date: string; primary: string }>>({
      date: [this.formatedCardDate || '', [Validators.required, cardExpiryValidator]],
      primary: [''],
    });

    this.subscribes();
  }

  nameToDash(param: any): string {
    return toDashCase(param);
  }

  submit(): void {
    this.action = this.billingService
      .updatePaymentMethod(this.card.id, this.form.value)
      .pipe(
        tap({ error: (error: APIErrorResponse) => this.errors = error.error.errors }),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.billingService.paymentMethodsChange();
        this.closeModal({
          success: true,
          isPrimary: !!this.form.get('primary').value,
        });
      });
  }

  private subscribes(): void {
    this
      .form
      .controls
      .date
      .valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.errors = [];
        formatCardExpiry((this.update_card_date as any).getNativeElementRef().nativeElement);
      });
  }

  private get formatedCardDate(): string {
    const date: string = this.card.expiration;
    const month: string = parseInt(date.split('-')[0], 10).toString();
    const year: string = parseInt(date.split('-')[1].slice(-2), 10).toString();

    return `${month} / ${year}`;
  }

  public get showSetPrimaryCheckbox(): boolean {
    return !this.card.primary;
  }

  closeModal(closeResult?: { success: boolean; isPrimary: boolean }): void {
    this.dialogRef.close(closeResult);
  }

}
