import type { NavItem } from '@core/types';

import { FEATURES, PERMITTED_ACTION, ROUTE, USER_ROLE } from '@consts/consts';

export const Training: NavItem[] = [
  {
    label: 'Coaching',
    routerLink: ROUTE.alias.TRAINING_COACHING,
    counter: null,
    roles: [USER_ROLE.agent, USER_ROLE.lender],
    permitted_actions: null,
    feature: FEATURES.TRAINING_COACHING,
    icon: null,
  },
  {
    label: 'Training Videos',
    routerLink: ROUTE.alias.TRAINING_VIDEO_GUIDES,
    counter: null,
    roles: [USER_ROLE.agent, USER_ROLE.lender],
    permitted_actions: [PERMITTED_ACTION.TRAINING_VIDEO_MANAGE],
    feature: FEATURES.TRAINING_VIDEO_GUIDES,
    icon: null,
  },
  {
    label: 'Realtor Connection Kit',
    routerLink: ROUTE.alias.TRAINING_REALTOR_CONNECTION_KIT,
    counter: null,
    roles: [USER_ROLE.lender],
    permitted_actions: [PERMITTED_ACTION.REALTOR_CONNECTION_KIT_MANAGE],
    feature: FEATURES.TRAINING_REALTOR_CONNECTION_KIT,
    icon: null,
  },
  {
    label: 'Growing Your Business',
    routerLink: ROUTE.alias.TRAINING_GROWING_YOUR_BUSINESS,
    counter: null,
    roles: [USER_ROLE.agent],
    permitted_actions: [PERMITTED_ACTION.REALTOR_CONNECTION_KIT_MANAGE],
    feature: FEATURES.TRAINING_GROWING_YOUR_BUSINESS,
    icon: null,
  },
];
