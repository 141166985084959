import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import type { Profile } from '@core/types';

// Consts
import { ROUTE } from '@consts/consts';

// Services
import { UserService } from '@core/services/user/user.service';
import { DialogRef } from '@angular/cdk/dialog';

@Component({
  templateUrl: './confirm-email-modal.component.html',
  styleUrls: ['./confirm-email-modal.component.scss'],
})
export class ConfirmEmailModalComponent {

  profile$: Observable<Profile> = this.userService.profile$;

  constructor(
    private router: Router,
    private userService: UserService,
    private dialogRef: DialogRef,
  ) {
  }

  goToProfile(): void {
    this.router.navigate([ROUTE.alias.PROFILE]);
    this.closeModal();
  }

  closeModal(): void {
    this.dialogRef.close();
  }

}
