import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  Router,
  CanLoad,
  Route,
} from '@angular/router';
import { map, take } from 'rxjs/operators';

import type { Observable } from 'rxjs';
import type { Profile, UserRole } from '@core/types';

// Consts
import { ROUTE, USER_ROLE } from '@consts/consts';

// Services
import { UserService } from '@core/services/user/user.service';

@Injectable({ providedIn: 'root' })
export class RoleGuard implements CanActivate, CanLoad {

  constructor(
    private router: Router,
    private userService: UserService,
  ) {}

  private getRedirectUrl(role: UserRole): string {
    switch (role) {
      case USER_ROLE.agent: {
        return ROUTE.alias.DASHBOARD_PRODUCTION; // todo:: switch to ROUTE.alias.DASHBOARD when Agent`s dashboard will be ready
      }
      case USER_ROLE.lender: {
        return ROUTE.alias.DASHBOARD;
      }
      case USER_ROLE.title_user: {
        return ROUTE.alias.DASHBOARD;
      }
      case USER_ROLE.sales: {
        return ROUTE.alias.USERS;
      }
      case USER_ROLE.owner: {
        return ROUTE.alias.USERS;
      }
      case USER_ROLE.customer_success: {
        return ROUTE.alias.USERS;
      }
      case USER_ROLE.marketing: {
        return ROUTE.alias.BANNERS;
      }
      default: {
        return '';
      }
    }
  }

  private doCheck(route: Route | ActivatedRouteSnapshot): Observable<boolean> {
    return this
      .userService
      .profile$
      .pipe(
        take(1),
        map((profile: Profile) => {
          const { role } = profile;
          const roles: string[] = route?.data?.canBeActivatedBy;

          if (!roles) { return true; }

          if (!~roles.indexOf(role)) {
            this.router.navigate([this.getRedirectUrl(role)]);

            return false;
          }

          return true;
        }),
      );
  }

  canLoad(route: Route): Observable<boolean> {
    return this.doCheck(route);
  }

  canActivate(activatedRoute: ActivatedRouteSnapshot): Observable<boolean> {
    return this.doCheck(activatedRoute);
  }

}
