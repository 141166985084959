import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Store } from '@ngxs/store';

import { Profile, updatePassword } from '@core/types';

// Const
import { PASSWORD_STRONG_VALIDATION_PATTERN } from '@consts/regex';

// Store
import { FTLActions } from '@modules/auth/sign-up/ftl/state/ftl.actions';
import { FTLStepName } from '@modules/auth/sign-up/ftl/state/ftl.model';
import { FtlState } from '@modules/auth/sign-up/ftl/state/ftl.state';
import { TypedFormGroup } from '@core/types/form-group-config.type';

@Component({
  selector: 'ftl-pass-step',
  templateUrl: './ftl-pass-step.component.html',
  styleUrls: ['../ftl.component.scss', './ftl-pass-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FtlPassStepComponent implements OnInit {

  profile: Profile = null;

  action$: Subscription;

  passwordMinLength: number = 6;

  form: FormGroup = this.fb.group<TypedFormGroup<updatePassword>>({
    password: ['', [
      Validators.required,
      Validators.minLength(this.passwordMinLength),
      Validators.pattern(PASSWORD_STRONG_VALIDATION_PATTERN),
    ]],
    password_confirmation: ['', [
      Validators.required,
      Validators.minLength(this.passwordMinLength),
      Validators.pattern(PASSWORD_STRONG_VALIDATION_PATTERN),
    ]],
  }, { updateOn: 'submit' });

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
  ) {}

  get error(): 'required' | 'minlength' | 'pattern' | 'match' | null {
    const { password, password_confirmation } = this.form.controls;

    if (!!password.getError('required') || !!password_confirmation.getError('required')) {
      return 'required';
    }

    if (!!password.getError('minlength') || !!password_confirmation.getError('minlength')) {
      return 'minlength';
    }

    if (password.getError('pattern') || password_confirmation.getError('pattern')) {
      return 'pattern';
    }

    if (password.value !== password_confirmation.value) {
      return 'match';
    }

    return null;
  }

  ngOnInit(): void {
    this.store
      .dispatch(new FTLActions.GetStepDataAction(FTLStepName.enter_password))
      .subscribe(() => {
        const { profile } = this.store.selectSnapshot(FtlState.ftlStepData);

        if (profile) {
          this.profile = profile;
          this.cdr.markForCheck();
        }
      });
  }

  submitPasswordStep(): void {
    this.form.markAsDirty();
    this.form.updateValueAndValidity();

    if (this.form.valid) {
      this.action$ = this.store
        .dispatch(new FTLActions.SubmitFtlStepAction(FTLStepName.enter_password, this.form.value))
        .subscribe();
    }
  }

}
