/// <reference types="@types/google.maps" />
import {
  Component,
  OnInit,
  NgZone,
  ViewChild,
  ElementRef,
  Input,
  Renderer2,
  forwardRef,
} from '@angular/core';
import {
  Validators,
  ControlValueAccessor,
  ControlContainer,
  FormBuilder,
  FormGroup,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { isEqual } from 'lodash-es';
import {
  FormError,
  remapGooglePlacesAddress,
  SentinelErrorHandler,
  Dictionary,
} from 'asap-team/asap-tools';
import { Loader } from '@googlemaps/js-api-loader';

import type { Address } from '@core/types';

// Consts
import { GoogleAPILoaderConfig } from '@consts/consts';
import { TypedFormGroup } from '@core/types/form-group-config.type';

@UntilDestroy()
@Component({
  selector: 'iq-form-control-address',
  templateUrl: './iq-form-control-address.component.html',
  styleUrls: ['./iq-form-control-address.component.scss'],
  // providers: provideRefs(IqFormControlPercentComponent), Return to this line after ng17 and update of ASAP-tools forwardRefs
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IqFormControlAddressComponent),
      multi: true,
    },
  ],
})
export class IqFormControlAddressComponent implements ControlValueAccessor, OnInit {

  @ViewChild('address', { static: true }) address: ElementRef;

  @Input() placeholder: string | null = 'Search Address';

  @Input() errorsModel: FormError[];

  @Input() formControlName: string;

  form: FormGroup;

  fullAddressFocus: boolean = false;

  isFirstChange: boolean = true;

  private DEBOUNCE_TIME: number = 500;

  private mapsAPILoader: Loader = new Loader(GoogleAPILoaderConfig);

  get addressControl(): FormControl {
    return this.controlContainer.control.get('address') as FormControl;
  }

  constructor(
    private controlContainer: ControlContainer,
    private renderer2: Renderer2,
    private fb: FormBuilder,
    private ngZone: NgZone,
    private sentinelErrorHandler: SentinelErrorHandler,
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.subscribes();
    this.initAutocompleteWidget();
  }

  onChange: any = () => { };

  onTouched: any = () => { };

  writeValue(value: Address): void {
    if (!value) {
      return;
    }

    this.form.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer2.setProperty(
      this.address.nativeElement,
      'disabled',
      isDisabled,
    );
  }

  blur(): void {
    this.fullAddressFocus = false;
    this.onTouched();
  }

  private initForm(): void {
    this.form = this.fb.group<TypedFormGroup<Address>>({
      full_address: ['', Validators.required],
      unit: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      street: ['', Validators.required],
      zip: ['', Validators.required],
      country: ['', Validators.required],
      lat: [null],
      lng: [null],
    });
  }

  private subscribes(): void {
    this.form.valueChanges
      .pipe(
        debounceTime(this.DEBOUNCE_TIME),
        distinctUntilChanged(isEqual),
        untilDestroyed(this),
      )
      .subscribe((address: Address) => {
        if (!address.full_address) {
          this.onChange(null);

          return;
        }

        if (address.lat && address.lng && !this.isFirstChange) {
          this.updateAddressByGeocoderResult(address.lat, address.lng);

          this.isFirstChange = false;

          return;
        }

        this.onChange(address);
      });
  }

  private async initAutocompleteWidget(): Promise<void> {
    try {
      await this.mapsAPILoader.load();

      const autocomplete: google.maps.places.Autocomplete = new google.maps.places.Autocomplete(
        this.address.nativeElement,
        {
          types: ['address'],
          componentRestrictions: { country: ['us'] },
        },
      );

      autocomplete.addListener('place_changed', () => {
        this.placesChangeHandler(autocomplete);
      });
    } catch (error) {
      this.sentinelErrorHandler.handleError(error);
    }
  }

  private async updateAddressByGeocoderResult(
    latitude: number,
    longitude: number,
  ): Promise<void> {
    try {
      await this.mapsAPILoader.load();

      const geocoder: google.maps.Geocoder = new google.maps.Geocoder();

      await geocoder.geocode(
        { location: { lat: latitude, lng: longitude } },
        (
          results: google.maps.GeocoderResult[],
          status: google.maps.GeocoderStatus,
        ) => this.geocoderChangeHandler(results, status),
      );
    } catch (error) {
      this.sentinelErrorHandler.handleError(error);
    }
  }

  private placesChangeHandler(
    autocomplete: google.maps.places.Autocomplete,
  ): void {
    this.ngZone.run(() => {
      const place: google.maps.places.PlaceResult = autocomplete.getPlace();

      if (!place.address_components) {
        return;
      }

      const formattedAddress: Dictionary<string> = remapGooglePlacesAddress(
        place.address_components,
        place.formatted_address,
      );

      this.form.patchValue({ ...formattedAddress });
    });
  }

  private geocoderChangeHandler(
    results: google.maps.GeocoderResult[],
    status: google.maps.GeocoderStatus,
  ): void {
    if (`${status}` === 'OK') {
      const place: google.maps.GeocoderResult = results[0];
      const { lat, lng, ...formattedAddress } = remapGooglePlacesAddress(
        place.address_components,
        place.formatted_address,
      );

      this.form.patchValue({
        ...formattedAddress,
        lat: '',
        lng: '',
      });
    } else {
      console.warn(`Geocoder failed due to: ${status}`);
    }
  }

}
