import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subscription, throwError } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  EventEmitter,
  Component,
  OnInit,
  Output,
  Input,
  OnChanges,
  SimpleChanges,
  SimpleChange,
  ViewChild,
} from '@angular/core';
import { QuillEditorComponent } from 'ngx-quill';
import { catchError, switchMap } from 'rxjs/operators';
import { Dictionary, FormErrors, phoneValidator } from 'asap-team/asap-tools';

import {
  CheckboxItem,
  UserRole,
  AccountProfile,
  Avatar,
  updateProfile,
  AccountDetails,
} from '@core/types';

// Consts
import { environment } from 'environments/environment';
import {
  USER_ROLE,
  REGISTRATION_STEP,
  FB_CAMP_ID,
  EMAIL_PATTERN,
  URL_PATTERN,
  JOB_TITLE_PATTERN,
  PROFILE_STATUS,
  profileErrors,
} from '@consts/consts';

// Components
import { ConfirmPhoneComponent } from '@commons/modals/confirm-phone/confirm-phone.component';
import { ConfirmEmailModalComponent } from '@commons/modals/confirm-email/confirm-email-modal.component';
import { ChangePasswordComponent } from '@commons/modals/change-password/change-password.component';

// Services
import { UserService } from '@core/services/user/user.service';
import { DictionaryService } from '@core/services/dictionary/dictionary.service';
import { IqModalService } from '@commons/iq-modal/iq-modal.service';
import { TypedFormGroup } from '@core/types/form-group-config.type';

type UserForm = updateProfile & AccountDetails & { pageLink: string };

@UntilDestroy()
@Component({
  selector: 'user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent implements OnInit, OnChanges {

  @ViewChild('editor', { static: true }) editor: QuillEditorComponent;

  @Input() profile: AccountProfile;

  @Input() isSignUp: boolean = false;

  @Input() isAccountEdit: boolean = false;

  @Input() buttonName: string = 'Save changes';

  @Input() updateSubscription: Subscription;

  @Input() states: CheckboxItem[];

  @Output() updatedUser: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  @Output() formChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  USER_ROLE: Dictionary<UserRole> = USER_ROLE;

  disclaimerFormItems: number[] = [1, 2, 3, 4, 5, 0];

  formErrors: FormErrors = profileErrors;

  form: FormGroup;

  privacyPolicy: string = environment.privacyPolicy;

  termsConditions: string = environment.termsConditions;

  avatarLoading: boolean = false;

  timeZones$: Observable<CheckboxItem []> = this.dictionaryService.timeZones$;

  mlsTooltipText: string = 'As a member of an MLS, you receive an number known as an MLS ID, Agent ID, or Public ID.. This serves as your primary "username" or "login" credential for accessing the MLS system, typically comprising a combination of letters, numbers, or both.';

  showSocialsInputs: boolean = false;

  get isRegistrationCompleted(): boolean {
    return this.userService.isRegistrationCompleted;
  }

  get isPhoneChanged(): boolean {
    return this.form.get('phone').dirty || this.form.get('phone').touched;
  }

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private iqModalService: IqModalService,
    private dictionaryService: DictionaryService,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group<TypedFormGroup<UserForm>>({
      username: [this.getValue('username'), [Validators.required, Validators.minLength(1)]],
      name: [this.getValue('name'), [Validators.required, Validators.minLength(3)]],
      company_name: [this.getValue('company_name'), [Validators.required, Validators.minLength(3)]],
      license_number: [this.getValue('license_number'), [Validators.required, Validators.minLength(3)]],
      company_license_number: [
        this.getValue('company_license_number'),
        [Validators.required, Validators.minLength(3), Validators.maxLength(50)],
      ],
      email: [this.getValue('email'), [Validators.required, Validators.pattern(EMAIL_PATTERN)]],
      phone: [this.getValue('phone'), [Validators.required, phoneValidator]],
      job_title: [this.getValue('job_title'), [Validators.maxLength(50), Validators.pattern(JOB_TITLE_PATTERN)]],
      facebook_campaign_id: [this.getValue('facebook_campaign_id'), [Validators.pattern(FB_CAMP_ID)]],
      facebook_lead_gen_campaign_id: [this.getValue('facebook_lead_gen_campaign_id'), [Validators.pattern(FB_CAMP_ID)]],
      pageLink: [{ value: this.getPageLink(), disabled: true }],
      facebook_url: [this.getSocialLink('facebook_url'), [Validators.pattern(URL_PATTERN)]],
      instagram_url: [this.getSocialLink('instagram_url'), [Validators.pattern(URL_PATTERN)]],
      linkedin_url: [this.getSocialLink('linkedin_url'), [Validators.pattern(URL_PATTERN)]],
      personal_website_url: [this.getSocialLink('personal_website_url'), [Validators.pattern(URL_PATTERN)]],
      realtor_url: [this.getSocialLink('realtor_url'), [Validators.pattern(URL_PATTERN)]],
      youtube_url: [this.getSocialLink('youtube_url'), [Validators.pattern(URL_PATTERN)]],
      zillow_url: [this.getSocialLink('zillow_url'), [Validators.pattern(URL_PATTERN)]],
      disclaimer_logo: [this.getValue('disclaimer_logo')],
      disclaimer_text: [this.getValue('disclaimer_text')],
      license_states: [this.getValue('license_states'), [Validators.required]],
      pipe_drive_url: [this.getValue('pipe_drive_url'), [Validators.minLength(3)]],
      time_zone: [this.getValue('time_zone'), [Validators.required]],
      ...(this.profile.role === USER_ROLE.agent ? { mls_number: [this.getValue('mls_number')] } : {}),
    });
    this.checkProfileForm();
    this.subscribes();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const state: SimpleChange = changes.updateSubscription;

    if (state && !!state.currentValue) {
      this.form.markAsPristine();
    }
  }

  isUserRole(roles: UserRole | UserRole[]): boolean {
    return this.userService.isUserRole(roles);
  }

  getValue(input: string): string {
    return this.profile[input] || '';
  }

  getSocialLink(input: string): string {
    return this.profile.socials ? this.profile.socials[input] : '';
  }

  getPageLink(): string {
    return this.profile.status === PROFILE_STATUS.ACTIVE ? `${environment.herUrl}/${this.profile.username}` : '';
  }

  expandSocialsInputs(): void {
    this.showSocialsInputs = !this.showSocialsInputs;
  }

  confirmEmail(): void {
    this
      .userService
      .requestConfirmEmail()
      .pipe(
        switchMap(() => this.iqModalService.open(ConfirmEmailModalComponent).closed),
        untilDestroyed(this),
      )
      .subscribe();
  }

  updateProfile(): void {
    this.updatedUser.emit(this.form);
    this.formChanged.emit(true);
  }

  confirmPhoneNumber(): void {
    this.iqModalService.open(ConfirmPhoneComponent);
  }

  changePassword(): void {
    this.iqModalService.open(ChangePasswordComponent);
  }

  uploadAvatar({ image, name }: { image: Blob; name: string }): void {
    this.avatarLoading = true;

    let method: Observable<Avatar>;

    if (this.userService.isUserRole(USER_ROLE.owner) && this.profile?.id) {
      method = this.userService.uploadCustomerAvatar(image, this.profile?.id, name);
    } else {
      method = this.userService.uploadAvatar(image, name);
    }

    method
      .pipe(
        untilDestroyed(this),
        catchError((error: any) => {
          this.avatarLoading = false;

          return throwError(() => error);
        }),
      )
      .subscribe((avatar: Avatar) => {
        this.avatarLoading = false;
        this.profile = {
          ...this.profile,
          avatar,
        };
      });
  }

  private subscribes(): void {
    this
      .form
      .valueChanges
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.formChanged.emit(false);
      });
  }

  private checkProfileForm(): void {
    if (this.profile.registration_step === REGISTRATION_STEP.updateProfile) {
      this.form.markAsDirty();
    }

    if (this.isUserRole([USER_ROLE.sales, USER_ROLE.owner])) {
      this.form.controls.company_name.setValidators([Validators.minLength(3)]);
      this.form.controls.license_number.setValidators([Validators.minLength(3)]);
      this.form.controls.company_license_number.setValidators([Validators.minLength(3)]);
    }

    if (this.isUserRole([USER_ROLE.sales, USER_ROLE.owner]) && !this.profile.status) {
      this.form.controls.facebook_campaign_id.disable();
      this.form.controls.facebook_lead_gen_campaign_id.disable();
    }
  }

  get isAgentRole(): boolean {
    return this.profile.role === USER_ROLE.agent;
  }

}
