import { IqSidebarConfig } from '@commons/iq-sidebar/models/iq-sidebar-config.model';

export const sidebarDefaultConfiguration: IqSidebarConfig = {
  closeOnEscape: true,
  closeOnBackdrop: true,
  bodyClass: null,
  wrapperDefaultClasses: null,
  wrapperClass: null,
  animationDuration: 300,
  autoFocus: false,
};
