import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Sentinel } from 'asap-team/asap-tools';
import posthog from 'posthog-js';

import { AppModule } from 'app/app.module';
import { environment } from 'environments/environment';

if (environment.production) {
  enableProdMode();
}

if (environment.production && environment.posthog?.apiKey) {
  posthog.init(
    environment.posthog.apiKey, {
      api_host: environment.posthog.apiHost,
      capture_pageview: false,
      capture_pageleave: true,
    },
  );
}

const sentinel: Sentinel = new Sentinel(environment);

sentinel.onguard();

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err: any) => console.warn(err));
