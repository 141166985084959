import { EmailSkeleton } from '@core/types';

export const LENDER_WELCOME_EMAIL_TEMPLATE: EmailSkeleton = {
  SUBJECT: 'Exciting News - Your Personalized myhomeIQ Report is Here!',
  BODY: '‌Hi %client_name%,\n\nI hope you’re having a wonderful day!\n\nIn the ever-changing real estate world, it’s important to stay informed about your most valuable asset - your home. That’s why I’m excited to share with you your custom myhomeIQ report, a tool designed to give you a clear picture of your home’s value and equity in today’s market.\n\nWhat’s more, your myhomeIQ report includes an essential feature - timely updates on interest rate changes. This means you’ll always be in the know about market trends and how they could impact your home financing options.\n\nPlease take a moment to review your personalized myhomeIQ report, which you’ll find in your inbox. Remember, I’m always here to discuss any aspect of your report or answer any questions you might have. Your comfort and understanding as a homeowner are my priority.\n\nLooking forward to catching up and hearing your thoughts on the report.\n\nWarm regards,\n\n%my_name%\n%my_job_title%\nLicense # %my_license%\n%my_phone%\n%my_email%\n\n%company_name%\nBrokerage License: %company_license%',
};

export const AGENT_WELCOME_EMAIL_TEMPLATE: EmailSkeleton = {
  SUBJECT: 'Exciting News - Your Personalized myhomeIQ Report is Here!',
  BODY: '‌Hi %client_name%,\n\nI hope you’re having a wonderful day!\n\nIn the ever-changing real estate world, it’s important to stay informed about your most valuable asset - your home. That’s why I’m excited to share with you your custom myhomeIQ report, a tool designed to give you a clear picture of your home’s value and equity in today’s market.\n\nWhat’s more, your myhomeIQ report includes an essential feature - timely updates on interest rate changes. This means you’ll always be in the know about market trends and how they could impact your home financing options.\n\nPlease take a moment to review your personalized myhomeIQ report, which you’ll find in your inbox. Remember, I’m always here to discuss any aspect of your report or answer any questions you might have. Your comfort and understanding as a homeowner are my priority.\n\nLooking forward to catching up and hearing your thoughts on the report.\n\nWarm regards,\n\n%my_name%\n%my_job_title%\nLicense # %my_license%\n%my_phone%\n%my_email%\n\n%company_name%\nBrokerage License: %company_license%',
};

export const SHORTCODES_LIST: string[] = [
  'invitee_name',
  'invitation_link',
  'my_name',
  'my_phone',
  'my_email',
  'my_license',
  'company_name',
  'company_license',
];
