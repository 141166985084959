import { Component, OnInit } from '@angular/core';
import { timer } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'countdown-modal',
  templateUrl: './countdown-modal.component.html',
  styleUrls: ['./countdown-modal.component.scss'],
})
export class CountdownModalComponent implements OnInit {

  progress: string = '100%';

  constructor(
    private dialogRef: DialogRef,
  ) {
  }

  ngOnInit(): void {
    let percent: number = 100;

    timer(1000, 100)
      .pipe(
        take(100),
        tap(() => {
          this.progress = `${percent}%`;
          percent -= 1;
        }),
      )
      .subscribe();
  }

  onCountdownEnd(): void {
    this.closeModal();
  }

  closeModal(): void {
    this.dialogRef.close();
  }

}
