<div class="tab-nav__item" *ngFor="let item of navigation; let i = index">
  <a class="tab-nav__link"
     [routerLink]="item.routerLink"
     [routerLinkActiveOptions]="{ exact: item.exactRouteActiveMatch }"
     routerLinkActive="active">
    <span class="tab-nav__label">{{ item.label }}</span>
    <tab-counters *ngIf="item.counter"
                  [collectionCounterName]="item.counter"
                  [isNewCounterDot]="!!item.isNewCounterDot"></tab-counters>
  </a>
</div>
