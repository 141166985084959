import { OverlayConfig } from '@angular/cdk/overlay';

export const overlayDefaultConfiguration: OverlayConfig = {
  /** Custom class to add to the overlay pane. */
  panelClass: ['iq-sidebar', 'iq-sidebar-fade-animation'],
  /** Whether the overlay has a backdrop. */
  hasBackdrop: true,
  /** Custom class to add to the backdrop */
  backdropClass: ['iq-backdrop'],
  /** The width of the overlay panel. If a number is provided, pixel units are assumed. */
  width: null,
  /** The height of the overlay panel. If a number is provided, pixel units are assumed. */
  height: null,
  /** The min-width of the overlay panel. If a number is provided, pixel units are assumed. */
  minWidth: null,
  /** The min-height of the overlay panel. If a number is provided, pixel units are assumed. */
  minHeight: null,
  /** The max-width of the overlay panel. If a number is provided, pixel units are assumed. */
  maxWidth: null,
  /** The max-height of the overlay panel. If a number is provided, pixel units are assumed. */
  maxHeight: null,
  /**
   * Direction of the text in the overlay panel. If a `Directionality` instance
   * is passed in, the overlay will handle changes to its value automatically.
   */
  direction: 'ltr',
  /**
   * Whether the overlay should be disposed of when the user goes backwards/forwards in history.
   * Note that this usually doesn't include clicking on links (unless the user is using
   * the `HashLocationStrategy`).
   */
  disposeOnNavigation: true,
};
