import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormErrors } from 'asap-team/asap-tools';

import type { AccountDetails, IMessengerDetails } from '@core/types';

// Consts
import { profileErrors } from '@consts/consts';
import { TypedFormGroup } from '@core/types/form-group-config.type';

@Component({
  selector: 'messenger-form',
  templateUrl: './messenger-form.component.html',
  styleUrls: ['./messenger-form.component.scss'],
})
export class MessengerFormComponent implements OnInit {

  @Input() action: Subscription;

  @Input() messenger: IMessengerDetails;

  @Output() updateMessenger: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  @Output() disableMessenger: EventEmitter<void> = new EventEmitter<void>();

  @Input() connected: boolean;

  profileFormErrors: FormErrors = profileErrors;

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group<TypedFormGroup<AccountDetails>>({ fb_messenger: [this.connected ? this.messenger.fb_messenger : '', [Validators.minLength(3)]] });
  }

  connect(): void {
    this.updateMessenger.emit(this.form);
  }

  disableIntegration(): void {
    this.disableMessenger.emit();
    this.form.reset();
  }

}
