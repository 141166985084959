import { Component } from '@angular/core';
import { Router } from '@angular/router';

// Consts
import { ROUTE } from '@consts/consts';
import { DialogRef } from '@angular/cdk/dialog';

@Component({
  templateUrl: './limit-attempts.component.html',
  styleUrls: ['./limit-attempts.component.scss'],
})
export class LimitAttemptsComponent {

  constructor(
    private route: Router,
    private dialogRef: DialogRef,
  ) {
  }

  confirm(): void {
    try {
      this.closeModal();
      this.route.navigate([ROUTE.alias.SIGN_UP]);
    } catch (error) {
      console.warn(error);
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }

}
