<header class="messenger-header flex m-b-3x">
  <div class="messenger-header__icon inline-svg--40" [inlineSVG]="'#icon-facebook-logo'"></div>
  <h2 class="messenger-header__content m-l-2x f-34-600">
    <span class="m-r-2x">Messenger</span>
    <span class="messenger-label f-14-normal f-c-grey-blue" *ngIf="connected">connected</span>
  </h2>
</header>
<div class="f-14-500 f-c-steel m-b-2x">
  Insert your Facebook username, so that customers can ask you a question from the landing page.
  Find your facebook username at <a [href]="messenger.website">Settings / General</a> section
</div>
<form [formGroup]="form" (ngSubmit)="connect()">
  <iq-form-control-fb-username class="m-b-1x"
                               label="Facebook username"
                               placeholder="username"
                               formControlName="fb_messenger"
                               [errorsModel]="profileFormErrors.fb_messenger"></iq-form-control-fb-username>
  <iq-button class="block m-t-2x"
             type="submit"
             [label]="connected ? 'Update' : 'Connect'"
             [disabled]="form.pristine || form.invalid"
             [busy$]="action"></iq-button>
</form>
<div class="f-12-400 f-c-steel m-b-2x m-t-3x" *ngIf="connected">
  <span class="m-r-1x" *ngIf="messenger.last_activity_at">
    Last connect: {{messenger.last_activity_at | date: 'dd MMM, yyyy - hh:mm a' }}
  </span>
  <span class="f-12-600 f-c-blue-main pointer" (click)="disableIntegration()">Disconnect</span>
</div>
