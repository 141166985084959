<div class="modal-content">
  <div class="promo-banner-preview">
    <div class="close inline-svg"
         aria-label="Close"
         [inlineSVG]="'#icon-close'"
         (click)="closeModal()"></div>
    <div class="promo-banner-preview__image"
         [style.backgroundImage]="'url('+ modalData?.banner?.original_url + ')'"></div>
    <div class="promo-banner-preview__controls">
      <div class="promo-banner-preview__control">
        <iq-button label="Download"
                   class="w-100 p-r-1x"
                   classes="w-100"
                   color="grey"
                   (onClick)="download(modalData?.banner?.original_url)"></iq-button>
      </div>
      <div class="promo-banner-preview__control">
        <iq-button label="Publish"
                   class="w-100"
                   classes="w-100"
                   (onClick)="share()"
                   [busy$]="shareButton$"></iq-button>
      </div>
    </div>
    <div class="promo-banner-preview__divider">
    </div>
    <div class="promo-banner-preview__promo-text-title">
      Suggested caption
    </div>
    <div class="promo-banner-preview__description p-b-2x">
      <p class="p-b-16 p-r-32 p-l-32 f-14-normal f-c-grey-main l-h-22"
         [innerHTML]="getDescription(modalData?.banner)"></p>
      <iq-button size="24"
                 color="grey"
                 class="p-l-32 p-b-16"
                 label="Copy caption"
                 [style]="'transparent'"
                 iconLeft="#icon-copy"
                 ngxClipboard=""
                 [cbContent]="modalData?.banner?.description">
      </iq-button>
    </div>
  </div>
</div>
