import { Component } from '@angular/core';

// Services
import { WorkingHoursService } from '@core/services/details/working-hours/working-hours.service';
import { DialogRef } from '@angular/cdk/dialog';

@Component({
  templateUrl: './activate-report-confirm.component.html',
  styleUrls: ['./activate-report-confirm.component.scss'],
})
export class ActivateReportConfirmComponent {

  modalData: { isPlural: boolean; title: string } = null;

  // Disabled till backend changes
  // showScheduleAlert: boolean = !this.workingHoursService.isWorkingHoursNow();
  showScheduleAlert: boolean = false;

  constructor(
    private workingHoursService: WorkingHoursService,
    private dialogRef: DialogRef,
  ) {
  }

  get scheduleAlertMessage(): string {
    return this.workingHoursService.nextAvailableWorkingSlot;
  }

  get scheduleAlertTooltip(): string {
    return `${this.workingHoursService.workingHoursTooltipText}.`;
  }

  confirm(): void {
    this.closeDialog(true);
  }

  closeDialog(closeResult?: boolean): void {
    this.dialogRef.close(closeResult);
  }

}
