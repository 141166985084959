import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { isEqual } from 'lodash-es';

import {
  BillingPlan,
  NavItem,
  PermittedAction,
  PremiumFeature,
  Profile,
} from '@core/types';
import { Dictionary } from 'asap-team/asap-tools';

// Consts
import { USER_ROLE, PREMIUM_FEATURES, ROUTE } from '@consts/consts';
import { tabsNavigationConfig, toolbarConfig } from '@consts/nav-config/nav-config';

// Services
import { UserService } from '@core/services/user/user.service';
import { FeatureFlagsService } from '@core/services/feature-flags/feature-flags.service';

@Injectable({ providedIn: 'root' })
export class NavigationBuilderService {

  constructor(
    private userService: UserService,
    private featureFlagsService: FeatureFlagsService,
  ) {}

  /**
   * Build config for Tabs navigation depends on current route
   *
   */

  private getBestMatch(collection: Dictionary<any>, key: string): string {
    const keys: string[] = Object.keys(collection).filter((item: string) => key.includes(item));

    if (keys?.length === 1) {
      return keys[0];
    }

    return keys?.reduce((bestMatch: string, element: string) => (bestMatch.length > element.length ? bestMatch : element), '');
  }

  buildTabsNavigation(url?: string): Observable<NavItem[]> {
    return this
      .userService
      .profile$
      .pipe(
        distinctUntilChanged(isEqual),
        map((profile: Profile) => {
          const isUser: boolean = !url ? this.userService.isUserRole([USER_ROLE.agent, USER_ROLE.lender]) : false;
          const key: string = this.getBestMatch(tabsNavigationConfig, url || (isUser ? ROUTE.alias.SETTINGS : ROUTE.alias.ADMIN_SETTINGS));
          const config: NavItem[] = tabsNavigationConfig[key];

          return config ? this.filterNavigationItems(config, profile) : null;
        }),
      );
  }

  /**
   * Build config for Toolbar navigation
   *
   */
  buildToolbarNavigation(): Observable<NavItem[]> {
    return this
      .userService
      .profile$
      .pipe(
        map((profile: Profile) => {
          if ((
            profile.role === USER_ROLE.agent
            || profile.role === USER_ROLE.lender)
            && !profile.registration_completed
          ) { return null; }

          return this.filterNavigationItems(toolbarConfig, profile);
        }),
      );
  }

  private checkPremiumAccess(features: PremiumFeature[], profile: Profile): boolean {
    return features.some((feat: PremiumFeature) => {
      if (feat.name === PREMIUM_FEATURES.white_label && feat.role === profile.role) {
        return profile.apps.white_label.enabled;
      }

      return true;
    });
  }

  private filterNavigationItems(navConfig: NavItem[], profile: Profile): NavItem[] {
    return navConfig
      .filter((item: NavItem) => {
        const isPremiumAccess: boolean = item.premium_features && !!item.premium_features.length;

        // Check feature is disabled by feature flag
        if (item?.feature && !this.featureFlagsService.isFeatureEnabled(item.feature)) {
          return false;
        }

        // Check for payment plan.
        if (item.restricted_plans?.length && item.restricted_plans.includes(profile.plan_name as BillingPlan)) {
          return false;
        }

        if (!item.permitted_actions) {
          if (!item.roles) {
            // Show navigation item cus no permission is set
            return true;
          }

          // Use provided roles to show navigation item
          const allowedByRole: boolean = item.roles.some((role: string) => profile.role === role);

          return isPremiumAccess ? (allowedByRole && this.checkPremiumAccess(item.premium_features, profile)) : allowedByRole;
        }

        // Check permission for current navigation item.
        if (!item.permitted_actions.some((action: PermittedAction) => profile?.permitted_actions?.includes(action))) { return false; }

        // Additional role check
        if (!item.roles) { return true; }

        // Premium feature check
        const allowedByRole: boolean = item.roles.some((role: string) => profile.role === role);

        return isPremiumAccess ? (allowedByRole && this.checkPremiumAccess(item.premium_features, profile)) : allowedByRole;
      });
  }

}
