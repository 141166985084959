<div class="pre-mover-count" [ngClass]="{'desktop-only' : desktopOnly}" [tooltip]="tooltipTemplate" container="body">
  <p class="pre-mover-count__title">Pre-mover<br/>score</p>
  <div class="pre-mover-count__progress">
    <img class="pre-mover-count__progress-image"
         [src]="'assets/images/pre-mover/' + range + '.svg'"
         alt="Pre-mover score"/>
    <p class="pre-mover-count__progress-value" [ngClass]="{ 'f-c-cloudy-blue': rate < 70 }">
      {{ rate / 100 | percent }}
    </p>
  </div>
</div>
<ng-template #low>
  <p>This homeowner is unlikely to sell their home in the next 12 months.</p>
</ng-template>
<ng-template #medium>
  <p>This homeowner has a neutral chance of selling their home in the next 12 months.</p>
</ng-template>
<ng-template #likely>
  <p>This homeowner is possibly going to sell their home in the next 12 months.</p>
</ng-template>
<ng-template #high>
  <p>It is very likely that this homeowner will sell their home in the next 12 months.</p>
</ng-template>
