import { Inject, Injectable } from '@angular/core';

// Consts
import { USER_ROLE } from '@consts/user';
import { CALENDLY_LINKS } from '@consts/calendly-links';

// Services
import { UserService } from '@core/services/user/user.service';
import { DOCUMENT } from '@angular/common';
import { WINDOW } from '@ng-web-apis/common';
import { CalendlyModalCallback } from '@core/services/calendly/models/calendly-modal-callback.model';
import { calendlyWindowListener } from '@core/services/calendly/helpers/calendly-window-listener.helper';

@Injectable({ providedIn: 'root' })
export class CalendlyService {

  constructor(
    private userService: UserService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: any,
  ) {}

  hasAssignedStaffTrainingUrl(): boolean {
    return !!this.userService.syncGetProfile()?.assigned_staff?.webinar_url;
  }

  hasAssignedStaffDemoUrl(): boolean {
    return !!this.userService.syncGetProfile()?.assigned_staff?.demo_url;
  }

  getDemoUrl(): string {
    if (this.hasAssignedStaffDemoUrl()) {
      return this.userService.syncGetProfile()?.assigned_staff?.demo_url;
    }

    return this.getDefaultUrl();
  }

  getTrainingUrl(): string {
    if (this.hasAssignedStaffTrainingUrl()) {
      return this.userService.syncGetProfile()?.assigned_staff?.webinar_url;
    }

    if (this.userService.isUserRole(USER_ROLE.lender)) {
      return CALENDLY_LINKS.lenderOnboarding;
    }

    return this.getDefaultUrl();
  }

  getDefaultUrl(): string {
    switch (this.userService.getUserRole()) {
      case USER_ROLE.agent: {
        return CALENDLY_LINKS.agent;
      }
      case USER_ROLE.lender: {
        return CALENDLY_LINKS.lender;
      }
      default: {
        return CALENDLY_LINKS.default;
      }
    }
  }

  getScheduleToActivateLink(calendlyLink: string): string {
    const params: URLSearchParams = new URLSearchParams();
    const date: Date = new Date();

    // Preparing date with paddings for day and month.
    // 1 becomes 01. E.g. 2024-01
    const year: number = date.getFullYear();
    const month: string = String(date.getMonth() + 1).padStart(2, '0');

    // Preparing the collection of params.
    params.append('embed_domain', this.document.location.host);
    params.append('embed_type', 'PopupText');
    params.append('month', `${year}-${month}`);

    return `${calendlyLink}?${params.toString()}`;
  }

  /**
   * Opens a new Calendly modal with observable callbacks for modal events.
   *
   * @function openInModal
   * @param {string} url - The URL to the Calendly scheduling page.
   * @returns {CalendlyModalCallback} An object containing observables for modal event tracking:
   * - `afterOpened` - Emits when the modal is first opened and visible to the user.
   * - `afterClosed` - Emits when the modal is closed, either by clicking the close button or backdrop.
   * - `onScheduled` - Emits when an appointment is successfully scheduled.
   *
   * @observable
   * @property {Subject<void>} afterOpened - Observable that fires when the modal opens.
   * @property {Subject<void>} afterClosed - Observable that fires when the modal closes.
   * @property {Subject<CalendlyEventPayload>} onScheduled - Observable that fires upon successful appointment scheduling.
   *
   * @example
   * const modal = this.calendlyService.openInModal(scheduleUrl);
   *
   * modal.afterOpened.subscribe({ next: () => console.log('Modal opened') });
   * modal.afterClosed.subscribe({ next: () => console.log('Modal closed') });
   * modal.onScheduled.subscribe({ next: (payload) => console.log('Scheduled an appointment', payload) });
   */
  openInModal(url: string): CalendlyModalCallback {
    const parentElement: HTMLElement = globalThis.document.body;
    const { afterOpened, afterClosed, onScheduled } = calendlyWindowListener();

    this.window.Calendly.initPopupWidget({ url, parentElement });

    return {
      afterOpened, afterClosed, onScheduled,
    };
  }

  appendToElement(url: string, element: HTMLElement): void {
    this.window.Calendly.initInlineWidget({ url, parentElement: element });
  }

  openInNewTab(scheduleUrl: string): void {
    this.window.open(scheduleUrl, '_blank')?.focus();
  }

}
