import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import { UserRole } from '@core/types';

// Services
import { UserService } from '@core/services/user/user.service';

// Components
import { RewardsSidebarComponent } from '@commons/sidebars/rewards-sidebar/rewards-sidebar.component';
import { IqSidebarService } from '@commons/iq-sidebar/iq-sidebar.service';

@UntilDestroy()
@Component({
  selector: 'rewards-nav',
  templateUrl: './rewards-nav.component.html',
  styleUrls: ['./rewards-nav.component.scss'],
})
export class RewardsNavComponent {

  constructor(
    private modalService: IqSidebarService,
    private userService: UserService,
  ) {
  }

  openRewards(): void {
    const userRole: UserRole = this.userService.getUserRole();

    this.modalService.open(RewardsSidebarComponent, { userRole });
  }

}
