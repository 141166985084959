<div class="search-filter">
  <input #inputRef
         class="search-filter__input"
         type="text"
         data-lpignore="true"
         [placeholder]="placeholder"
         [formControl]="placesControl"
         (focus)="onFocus(true)"
         (blur)="onFocus()"/>

  <!--Reset button-->
  <div *ngIf="inputRef?.value"
       class="inline-svg"
       [inlineSVG]="'#icon-close'"
       (click)="clear()"></div>

  <!--List of predictions-->
  <div class="search-result" [class.show]="isShowPredictions && predictions?.length">
    <div class="search-result-item pointer"
         *ngFor="let prediction of predictions"
         (click)="selectSuggestion(prediction)">{{ prediction.description }}</div>
  </div>
</div>

