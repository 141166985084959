import type { NavItem } from '@core/types';

import { ROUTE, USER_ROLE, PERMITTED_ACTION } from '@consts/consts';

export const UserSettings: NavItem[] = [
  {
    label: 'Profile',
    routerLink: ROUTE.alias.PROFILE,
    counter: null,
    roles: [USER_ROLE.agent, USER_ROLE.lender],
    permitted_actions: [PERMITTED_ACTION.PROFILE_MANAGE],
    icon: '#icon-menu-profile',
  },
  {
    label: 'Billing',
    routerLink: ROUTE.alias.BILLING,
    counter: null,
    roles: [USER_ROLE.agent, USER_ROLE.lender],
    permitted_actions: [PERMITTED_ACTION.BILLING_MANAGE],
    icon: '#icon-menu-billing',
  },
  {
    label: 'Homeowners',
    routerLink: ROUTE.alias.HOMEOWNERS_SETTINGS,
    counter: null,
    roles: [USER_ROLE.agent, USER_ROLE.lender],
    permitted_actions: [PERMITTED_ACTION.HOMEOWNERS_MANAGE],
    icon: '#icon-menu-homeowners',
  },
  {
    label: 'Buyers',
    routerLink: ROUTE.alias.BUYERS_SETTINGS,
    counter: null,
    roles: [USER_ROLE.agent, USER_ROLE.lender],
    permitted_actions: [PERMITTED_ACTION.BUYERS_LANDING_MANAGE],
    icon: '#icon-menu-buyers',
  },
  {
    label: 'Get Discount',
    routerLink: ROUTE.alias.REFERRAL_MANAGE,
    counter: null,
    roles: [USER_ROLE.lender],
    permitted_actions: [PERMITTED_ACTION.REFERRAL_MANAGE],
    icon: '#icon-menu-discount',
    highlighted: true,
  },
  {
    label: 'Get Discount',
    routerLink: ROUTE.alias.REFERRAL_LIST,
    counter: null,
    roles: [USER_ROLE.lender],
    permitted_actions: [PERMITTED_ACTION.REFERRAL_VIEW],
    icon: '#icon-menu-discount',
    highlighted: true,
  },
  {
    label: 'Get Discount',
    routerLink: ROUTE.alias.REFERRAL_EXPIRED,
    counter: null,
    roles: [USER_ROLE.lender],
    permitted_actions: [PERMITTED_ACTION.REFERRAL_EXPIRED],
    icon: '#icon-menu-discount',
    highlighted: true,
  },
];
