import { Component, OnInit } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { DialogRef } from '@angular/cdk/dialog';

@Component({
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class VideoComponent implements OnInit {

  id: string;

  customUrl!: string;

  videoUrl: SafeResourceUrl;

  constructor(
    private sanitizer: DomSanitizer,
    private dialogRef: DialogRef,
  ) {
  }

  ngOnInit(): void {
    this.videoUrl = this.sanitizeVideoUrl(this.id);
  }

  private sanitizeVideoUrl(id: string = '299319152'): SafeResourceUrl {
    const url: string = this.customUrl || `https://player.vimeo.com/video/${id}?autoplay=0&title=0&byline=0&portrait=0`;

    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  closeModal(): void {
    this.dialogRef.close();
  }

}
