import type { Dictionary } from 'asap-team/asap-tools';
import type { NavItem } from '@core/types/misc';

// Consts
import { PERMITTED_ACTION } from '../permitted-actions';
import { USER_ROLE, COLLECTION_COUNTER_NAME } from '../consts';
import { ROUTE } from '../routes';

import { AdminSettings } from './admin-settings';
import { Buyers } from './buyers';
import { Homeowners } from './homeowners';
import { Banners } from './banners';
import { UserSettings } from './user-settings';
import { Partners } from './partners';
import { Training } from './training';
import { LandingPagesSettings } from './landing-pages-settings';
import { HealthMetrics } from './health-metrics';
import { Analytics } from './analytics';
import { Dashboard } from './dashboard';
import { Marketing } from './marketing';

export const toolbarConfig: NavItem[] = [
  // Agent/Lender navigation
  {
    label: 'Dashboard',
    routerLink: ROUTE.alias.DASHBOARD,
    counter: null,
    roles: [USER_ROLE.agent, USER_ROLE.lender],
    permitted_actions: [PERMITTED_ACTION.DASHBOARD_VIEW],
    icon: '/assets/images/toolbar-config/dashboard-icon.svg',
  },
  {
    label: 'Analytics',
    routerLink: ROUTE.alias.ANALYTICS,
    counter: [], // kossr todo:: Counters?
    roles: [USER_ROLE.agent, USER_ROLE.lender],
    permitted_actions: [PERMITTED_ACTION.HOME_SHOPPER],
    icon: '/assets/images/toolbar-config/homeowners-icon.svg', // kossr todo:: Add appropriate icon
  },
  {
    label: 'Homeowners',
    routerLink: ROUTE.alias.HOMEOWNERS,
    counter: [COLLECTION_COUNTER_NAME.SELLER_DIGEST, COLLECTION_COUNTER_NAME.MORTGAGE_CHECKUPS, COLLECTION_COUNTER_NAME.SELLER_TRAFFIC],
    roles: [USER_ROLE.agent, USER_ROLE.lender],
    permitted_actions: [PERMITTED_ACTION.HOMEOWNERS_VIEW],
    icon: '/assets/images/toolbar-config/homeowners-icon.svg',
  },
  {
    label: 'Buyers',
    routerLink: ROUTE.alias.BUYERS,
    counter: [COLLECTION_COUNTER_NAME.BUYER_TRAFFIC, COLLECTION_COUNTER_NAME.BUYER_TRAFFIC],
    roles: null,
    permitted_actions: [PERMITTED_ACTION.BUYERS_VIEW],
    icon: '/assets/images/toolbar-config/buyers-icon.svg',
  },
  {
    label: 'Intelligence',
    routerLink: ROUTE.alias.PARTNERSHIP,
    counter: COLLECTION_COUNTER_NAME.PARTNERSHIPS_INCOMING_COUNTER,
    counterName: 'Invite',
    roles: [USER_ROLE.lender],
    permitted_actions: [PERMITTED_ACTION.PARTNERS_VIEW],
    icon: '/assets/images/toolbar-config/intelligence-icon.svg',
  },
  {
    label: 'Loan Officer',
    routerLink: ROUTE.alias.PARTNERSHIP,
    counter: COLLECTION_COUNTER_NAME.PARTNERSHIPS_INCOMING_COUNTER,
    counterName: 'Invite',
    roles: [USER_ROLE.agent],
    permitted_actions: [PERMITTED_ACTION.PARTNERS_VIEW],
    icon: '/assets/images/toolbar-config/intelligence-icon.svg',
  },
  {
    label: 'Training',
    routerLink: ROUTE.alias.TRAINING,
    counter: null,
    roles: [USER_ROLE.agent, USER_ROLE.lender],
    permitted_actions: [PERMITTED_ACTION.TRAINING_VIEW],
    icon: '/assets/images/toolbar-config/training-icon.svg',
  },
  {
    label: 'Marketing',
    routerLink: ROUTE.alias.MARKETING,
    counter: null,
    roles: [USER_ROLE.agent, USER_ROLE.lender],
    icon: '/assets/images/toolbar-config/marketing-icon.svg',
  },
  // Admin/Owner navigation
  {
    label: 'Users',
    routerLink: ROUTE.alias.USERS,
    counter: null,
    roles: null,
    permitted_actions: [PERMITTED_ACTION.ACCOUNTS_VIEW],
    icon: null,
  },
  {
    label: 'Promo',
    routerLink: ROUTE.alias.PROMO_CODES,
    counter: null,
    roles: null,
    permitted_actions: [PERMITTED_ACTION.PROMO_CODES_VIEW],
    icon: null,
  },
  {
    label: 'Databases',
    routerLink: ROUTE.alias.UPLOADS,
    counter: COLLECTION_COUNTER_NAME.UNCLAIMED_UPLOADED_DATABASES,
    roles: null,
    permitted_actions: [PERMITTED_ACTION.DATABASES_VIEW],
    icon: null,
  },
  {
    label: 'Banners',
    routerLink: ROUTE.alias.BANNERS,
    roles: null,
    permitted_actions: [PERMITTED_ACTION.BANNERS_VIEW],
    icon: null,
  },
  {
    label: 'Partners',
    routerLink: ROUTE.alias.PARTNERS,
    roles: null,
    permitted_actions: [PERMITTED_ACTION.ENTERPRISES_VIEW],
    icon: null,
  },
  {
    label: 'Health Metrics',
    routerLink: ROUTE.alias.HEALTH_METRICS,
    roles: [USER_ROLE.sales, USER_ROLE.owner, USER_ROLE.customer_success],
    permitted_actions: [PERMITTED_ACTION.SYSTEM_METRICS_VIEW],
    icon: null,
  },
  {
    label: 'Settings',
    routerLink: ROUTE.alias.ADMIN_SETTINGS,
    counter: null,
    roles: [USER_ROLE.sales, USER_ROLE.owner, USER_ROLE.customer_success],
    permitted_actions: [PERMITTED_ACTION.STAFF_PROFILE_MANAGE],
    icon: null,
  },
];

export const tabsNavigationConfig: Dictionary<NavItem[]> = {
  // Dashboard
  [ROUTE.alias.DASHBOARD]: Dashboard,
  [ROUTE.alias.DASHBOARD_PRODUCTION]: Dashboard,

  // Analytics
  [ROUTE.alias.ANALYTICS_ACTIVE_BUYERS]: Analytics,

  // Homeowners
  [ROUTE.alias.HOMEOWNERS_REPORTS]: Homeowners,
  [ROUTE.alias.HOMEOWNERS_MORTGAGE]: Homeowners,
  [ROUTE.alias.HOMEOWNERS_ACTIVITY]: Homeowners,
  [ROUTE.alias.HOMEOWNERS_TRAFFIC]: Homeowners,

  // Buyers
  [ROUTE.alias.BUYERS_TRAFFIC]: Buyers,

  // Settings
  [ROUTE.alias.SETTINGS]: UserSettings,
  [ROUTE.alias.HOMEOWNERS_LP]: LandingPagesSettings,

  // Admin Settings
  [ROUTE.alias.ADMIN_SETTINGS]: AdminSettings,

  // Banners
  [ROUTE.alias.BANNERS_BUYERS]: Banners,
  [ROUTE.alias.BANNERS_HOMEOWNERS]: Banners,

  // Partners
  [ROUTE.alias.PARTNERS_ENTERPRISE]: Partners,

  // Health Metrics
  [ROUTE.alias.HEALTH_METRICS]: HealthMetrics,

  // Training
  [ROUTE.alias.TRAINING_COACHING]: Training,
  [ROUTE.alias.TRAINING_VIDEO_GUIDES]: Training,
  [ROUTE.alias.TRAINING_REALTOR_CONNECTION_KIT]: Training,
  [ROUTE.alias.TRAINING_GROWING_YOUR_BUSINESS]: Training,

  // Marketing
  [ROUTE.alias.MARKETING_SELLER_TRAFFIC_BANNERS]: Marketing, // Home Value Banners & Refinance Banners
  [ROUTE.alias.MARKETING_HOME_WEALTH_TRAFFIC_BANNERS]: Marketing, // Home Wealth Banners
  [ROUTE.alias.MARKETING_BUYER_TRAFFIC_BANNERS]: Marketing, // Buyer Banners
  [ROUTE.alias.MARKETING_FLYERS]: Marketing, // Flyers
};
