<global-alert-item *ngFor="let alert of alerts$ | async" [type]="alert"></global-alert-item>
<header class="toolbar hiq-container">
  <div (click)="openMenu()" class="burger-menu-button pointer">
      <div></div>
      <div></div>
      <div></div>
  </div>
  <div class="toolbar__logo" (click)="logoRedirect()">
    <img alt="logo" src="assets/components/toolbar/logo.svg"/>
  </div>
  <desktop-nav class="toolbar__nav" [navigation]="menuService.navigation"></desktop-nav>
  <div class="toolbar__profile-wrapper">
    <a *ngIf="isTrialPlan" class="toolbar__upgrade-link" [routerLink]="[ROUTE.alias.UPGRADE_SUBSCRIPTION]">
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 14" fill="none">
        <path d="M11.2507 5.72098C11.1385 5.5366 10.9296 5.42274 10.7033 5.42274H7.0502L8.38353 0.924373C8.38353 0.658079 8.19362 0.424682 7.91981 0.354355C7.64597 0.284147 7.35583 0.394227 7.2112 0.623369L0.754544 7.68531C0.639277 7.868 0.637303 8.09462 0.749378 8.27905C0.861495 8.46343 1.07044 8.57729 1.29677 8.57729H4.94984L3.61651 13.0757C3.61651 13.3419 3.80638 13.5753 4.08023 13.6457C4.3547 13.7161 4.64451 13.6054 4.78884 13.3767L11.2455 6.31472C11.3608 6.13203 11.3627 5.90541 11.2507 5.72098Z" fill="url(#paint0_radial_15079_2254)"/>
        <defs>
          <radialGradient id="paint0_radial_15079_2254" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(7.00003 1.00003) rotate(103.496) scale(12.855 6.65015)">
            <stop stop-color="#00A3FF"/>
            <stop offset="1" stop-color="#DD0DFF"/>
          </radialGradient>
        </defs>
      </svg>
      <span>Upgrade</span>
    </a>
    <rewards-nav *permittedIf="PERMITTED_ACTION.KUDOS_VIEW" class="toolbar__rewards-nav"></rewards-nav>
    <profile-actions class="toolbar__profile-actions" [navigationItems]="profileMenuItems"></profile-actions>
  </div>
</header>
<ng-container #menuViewContainer></ng-container>
