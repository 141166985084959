import type { NavItem } from '@core/types';

import { ROUTE, USER_ROLE, PERMITTED_ACTION } from '@consts/consts';

export const LandingPagesSettings: NavItem[] = [
  {
    label: 'Home Wealth',
    routerLink: ROUTE.alias.HOME_WEALTH_LANDING,
    counter: null,
    roles: [USER_ROLE.agent, USER_ROLE.lender],
    permitted_actions: [PERMITTED_ACTION.HOME_WEALTH_LANDING_MANAGE],
    icon: null,
  },
  {
    label: 'Refinance',
    routerLink: ROUTE.alias.HOME_VALUE_LANDING,
    counter: null,
    roles: [USER_ROLE.lender],
    permitted_actions: [PERMITTED_ACTION.HOMEOWNERS_LANDING_MANAGE],
    icon: null,
  },
  {
    label: 'Home Value',
    routerLink: ROUTE.alias.HOME_VALUE_LANDING,
    counter: null,
    roles: [USER_ROLE.agent],
    permitted_actions: [PERMITTED_ACTION.HOMEOWNERS_LANDING_MANAGE],
    icon: null,
  },
];
