import { Component } from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'lending-pad-info',
  templateUrl: './lending-pad-info.component.html',
  styleUrls: ['./lending-pad-info.component.scss'],
})
export class LendingPadInfoComponent {

  constructor(
    private dialogRef: DialogRef,
  ) {
  }

  closeModal(): void {
    this.dialogRef.close();
  }

}
