<form class="update-card-form modal-content"
      novalidate=""
      autocomplete="off"
      name="form"
      [formGroup]="form"
      (ngSubmit)="submit()">
  <div class="modal-header">
    <h4 class="modal-title">Edit Card</h4>
    <div class="close" aria-label="Close" (click)="closeModal()"></div>
  </div>
  <div class="modal-body" [ngClass]="showSetPrimaryCheckbox?'p-b-3x':'p-b-5x'">
    <iq-form-control-text #update_card_date
                          label="Expiration date"
                          labelStyle="content"
                          placeholder="MM / YY"
                          formControlName="date"
                          [errorsModel]="formErrors.date">
      <div class="card-props" prepend-content="">
        <img *ngIf="card?.card_type"
             src="assets/components/payment-methods/payment-method-icons/{{nameToDash(card?.card_type)}}.svg"/>
        <span class="card-props-date">Ending in {{ card?.last4 }}</span>
      </div>
    </iq-form-control-text>
    <div class="control-error-message text-left" *ngFor="let error of errors">{{ error.detail }}</div>
    <div class="checkbox" *ngIf="showSetPrimaryCheckbox">
      <input class="checkbox__input" id="setPrimaryCardCheckbox" type="checkbox" formControlName="primary"/>
      <label class="checkbox__label f-14-normal f-c-grey-main" for="setPrimaryCardCheckbox">
        Make this my primary card
      </label>
    </div>
  </div>
  <div class="modal-footer">
    <div class="btns-group">
      <div class="btns-group-item">
        <iq-button class="block"
                   type="submit"
                   label="Update Subscription"
                   [disabled]="form.invalid"
                   [busy$]="action"></iq-button>
      </div>
      <div class="btns-group-item">
        <iq-button class="block" label="Cancel" color="grey" [style]="'outline'" (onClick)="closeModal()"></iq-button>
      </div>
    </div>
  </div>
</form>
