import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DialogRef } from '@angular/cdk/dialog';

@Component({
  templateUrl: './youtube-video.component.html',
  styleUrls: ['./youtube-video.component.scss'],
})
export class YoutubeVideoComponent implements OnInit {

  id: string;

  videoUrl: SafeResourceUrl;

  constructor(
    private sanitizer: DomSanitizer,
    private dialogRef: DialogRef,
  ) {
  }

  ngOnInit(): void {
    this.videoUrl = this.sanitizeVideoUrl(this.id);
  }

  sanitizeVideoUrl(id: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(id);
  }

  closeModal(): void {
    this.dialogRef.close();
  }

}
