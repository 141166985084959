import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';

// Consts
import { profileErrors } from '@consts/form-errors';
import { ADD_MLS_ID_MODAL_DISPLAYED } from '@consts/consts';

// Services
import { UserService } from '@core/services/user/user.service';
import {
  APIErrorResponse,
  COMMON_TOAST,
  FormErrors,
  ResponseErrorHandler,
  VaultService,
} from 'asap-team/asap-tools';
import { ToastrService } from 'ngx-toastr';
import { DialogRef } from '@angular/cdk/dialog';
import { TypedFormGroup } from '@core/types/form-group-config.type';
import { Profile } from '@core/types';

@UntilDestroy()
@Component({
  templateUrl: './add-mls-agent-id.component.html',
  styleUrls: ['./add-mls-agent-id.component.scss'],
})
export class AddMlsAgentIdComponent implements OnInit {

  form: FormGroup;

  formErrors: FormErrors = profileErrors;

  updateProfileAction$: Subscription;

  todayDate: Date = new Date();

  constructor(
    private builder: FormBuilder,
    private userService: UserService,
    private toastr: ToastrService,
    private dialogRef: DialogRef,
    private responseErrorHandler: ResponseErrorHandler,
    private vaultService: VaultService,
  ) {
  }

  ngOnInit(): void {
    this.form = this.builder.group<TypedFormGroup<Profile>>({ mls_number: ['', [Validators.required]] });

    this.vaultService.set(ADD_MLS_ID_MODAL_DISPLAYED, this.todayDate.setDate(+1));
  }

  completeProfile(): void {
    this.updateProfileAction$ = this
      .userService
      .updateProfile(this.form.value)
      .pipe(
        untilDestroyed(this),
      )
      .subscribe({
        next: () => {
          this.toastr.success(COMMON_TOAST.CHANGES_SAVED);
          this.closeModal();
        },
        error: (error: APIErrorResponse) => {
          this.responseErrorHandler.process(error, this.form, this.formErrors);
        },
      });
  }

  closeModal(): void {
    this.dialogRef.close();
  }

}
