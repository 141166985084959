<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">Archive report</h4>
    <div class="close inline-svg" aria-label="Close" [inlineSVG]="'#icon-close'" (click)="closeModal()"></div>
  </div>
  <div class="modal-body">
    <div class="modal-body__text">
      Report updating and sending will be deactivated. The homeowner won`t be able to view the report anymore.
    </div>
    <div class="modal__accept m-t-2x" *ngIf="!mode">
      <form class="checkbox" [formGroup]="form">
        <input class="checkbox__input" id="show" type="checkbox" formControlName="show"/>
        <label class="checkbox__label" for="show">Do not show me this message again</label>
      </form>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row row-fix">
      <div class="col col-fix">
        <iq-button class="block" label="Cancel" color="grey" [style]="'outline'" (onClick)="closeModal()"></iq-button>
      </div>
      <div class="col col-fix">
        <iq-button class="block" label="Archive" [color]="'red'" (onClick)="confirm()"></iq-button>
      </div>
    </div>
  </div>
</div>
