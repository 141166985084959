import { Component, Input } from '@angular/core';

// Types
import { Socials } from '@core/types';

@Component({
  selector: 'social-media-links',
  templateUrl: './socials.component.html',
  styleUrls: ['./socials.component.scss'],
})
export class SocialsComponent {

  @Input() socials: Socials;

}
