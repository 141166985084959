<div class="general-info">
  <ng-content select="[backBtnSlot]"></ng-content>
  <ng-content select="[inviteActionsSlot]"></ng-content>

  <ng-container *ngIf="generalInfo">
    <h4 class="info-title m-t-3x p-t-3x p-b-3x f-16-600 f-c-grey-main l-h-24">
      {{ type === 'lender' ? 'Loan Officer' : 'Agent' }} info
    </h4>
    <div class="agent-info">
      <div class="agent-info-row">
        <div class="agent-info-col agent-info-col--label">Email</div>
        <div class="agent-info-col agent-info-col--value text-overflow pointer"
             [attr.title]="generalInfo?.email"
             ngxClipboard
             [cbContent]="generalInfo?.email || null"
             (click)="generalInfo?.email && copy();">
          {{ generalInfo?.email || '-' }}
        </div>
      </div>
      <div class="agent-info-row">
        <div class="agent-info-col agent-info-col--label">Phone</div>
        <div class="agent-info-col agent-info-col--value text-overflow pointer"
             ngxClipboard
             [cbContent]="generalInfo?.phone || null"
             (click)="generalInfo?.phone && copy();">
          {{ (generalInfo?.phone | phone) || '-' }}
        </div>
      </div>
      <div class="agent-info-row">
        <div class="agent-info-col agent-info-col--label">License</div>
        <div class="agent-info-col agent-info-col--value text-overflow">{{ generalInfo?.license_number || '-' }}</div>
      </div>
      <div class="agent-info-row">
        <div class="agent-info-col agent-info-col--label">
          {{ type === 'lender' ? 'Brokerage' : 'Company`'}}
        </div>
        <div class="agent-info-col agent-info-col--value">{{ generalInfo?.company_name || '-' }}</div>
      </div>
      <div class="agent-info-row">
        <div class="agent-info-col agent-info-col--label">
          {{ type === 'lender' ? 'Broker Lic.' : 'Company Lic.'}}
        </div>
        <div class="agent-info-col agent-info-col--value text-overflow">
          {{ generalInfo?.company_license_number || '-' }}
        </div>
      </div>

      <div *ngIf="type === 'agent'" class="agent-info-row">
        <div class="agent-info-col agent-info-col--label">BA Comm.</div>
        <div class="agent-info-col agent-info-col--value text-overflow">
          <ng-container *ngIf="generalInfo?.commissions?.length && generalInfo?.commissions[0] as baComm; else baCommission">
            {{ baComm.value + '%' }}
          </ng-container>
          <ng-template #baCommission>2.5% (Average)</ng-template>
        </div>
      </div>
      <div *ngIf="type === 'agent'" class="agent-info-row">
        <div class="agent-info-col agent-info-col--label">LA Comm.</div>
        <div class="agent-info-col agent-info-col--value text-overflow">
          <ng-container *ngIf="generalInfo?.commissions?.length && generalInfo?.commissions[1] as laComm; else laCommission">
            {{ laComm.value + '%' }}
          </ng-container>
          <ng-template #laCommission>2.5% (Average)</ng-template>
        </div>
      </div>
      <div class="agent-info-row">
        <div class="agent-info-col agent-info-col--label">Socials</div>
        <div class="agent-info-col agent-info-col--value text-overflow">
          <social-media-links [socials]="generalInfo?.socials"></social-media-links>
        </div>
      </div>
    </div>
  </ng-container>
</div>
