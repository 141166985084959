import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { filter, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';


import type { Profile } from '@core/types';

// Consts
import { USER_ROLE } from '@consts/consts';

// Components
import { CropAvatarComponent } from '@commons/modals/crop-avatar/crop-avatar.component';

// Services
import { UserService } from '@core/services/user/user.service';
import { IqModalService } from '@commons/iq-modal/iq-modal.service';

@UntilDestroy()
@Component({
  selector: 'user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
})
export class UserAvatarComponent implements OnInit {

  @ViewChild('imageControl', { static: false }) imageControl: ElementRef;

  @Input() avatar: string;

  @Input() role: string;

  @Input() username: string;

  @Input() canEdit: boolean = true;

  @Input() loading: boolean = false;

  @Output() imageSelected: EventEmitter<{ image: Blob; name: string }> = new EventEmitter<{ image: Blob; name: string }>();

  get avatarSrc(): string {
    if (this.avatar) { return this.avatar; }

    if (this.role === USER_ROLE.lender) {
      return 'assets/components/profile/profile-loan-officer.svg';
    }

    return 'assets/components/profile/profile-realtor.svg';
  }

  constructor(
    private userService: UserService,
    private iqModalService: IqModalService,
  ) {}

  ngOnInit(): void {
    this.subscribes();
  }

  onImageSelected(event: Event): void {
    const target: HTMLInputElement = event.target as HTMLInputElement;

    if (!target?.files?.length) { return; }

    const name: string = `${target.files[0].name}_${Date.now()}`;

    this
      .iqModalService
      .open(
        CropAvatarComponent,
        { imageChangedEvent: event },
        { position: 'top', disableClose: true },
      )
      .closed
      .pipe(
        filter((value: Blob) => !!value),
        tap(() => { this.imageControl.nativeElement.value = null; }),
        untilDestroyed(this),
      )
      .subscribe((image: Blob) => {
        this.imageSelected.emit({ image, name });
      });
  }

  private subscribes(): void {
    this
      .userService
      .profile$
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(
        (profile: Profile) => {
          if (profile.username === this.username) {
            this.avatar = profile.avatar.medium;
          }
        },
      );
  }

}
