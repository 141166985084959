import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ResponseErrorHandler, FormErrors } from 'asap-team/asap-tools';

import type { Profile, APIErrorResponse } from '@core/types';

// Consts
import { COMMON_TOAST, signUpErrors } from '@consts/consts';

// Services
import { UserService } from '@core/services/user/user.service';
import { DialogRef } from '@angular/cdk/dialog';

@UntilDestroy()
@Component({
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {

  step: any = {
    current: true,
    new: false,
  };

  form: FormGroup;

  saveAction: Subscription;

  validateAction: Subscription;

  formErrors: FormErrors = signUpErrors;

  errors: string[];

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private toastr: ToastrService,
    private dialogRef: DialogRef,
    private responseErrorHandler: ResponseErrorHandler,
  ) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  nextStep(): void {
    this.errors = [];
    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.form.controls.password.setValidators([Validators.required, Validators.minLength(6)]);
    this.step = {
      current: false,
      new: true,
    };
  }

  save(): void {
    this.form.controls.password_confirmation.setValue(this.form.value.password);

    const observable: Observable<Profile> = this.userService.updateSettings(this.form);

    this.saveAction = observable
      .pipe(
        untilDestroyed(this),
      )
      .subscribe({
        next:         () => {
          this.toastr.success(COMMON_TOAST.SETTINGS_SAVED);
          this.closeModal();
        },
        error: (error: APIErrorResponse) => {
          this.errors = this.responseErrorHandler.processWithoutControls(error.error.errors);
        },
      });
  }

  validateCurrentPassword(): void {
    const observable: Observable<void> = this.userService.validatePassword(this.form);

    this.validateAction = observable
      .subscribe({
        next:         () => {
          this.nextStep();
        },
        error: (error: APIErrorResponse) => {
          this.form.controls.current_password.setErrors({ api_error: true });
          this.errors = this.responseErrorHandler.processWithoutControls(error.error.errors);
        },
      });
  }

  private initForm(): void {
    this.form = this.fb.group(
      {
        email: [''],
        password: ['', [Validators.minLength(6)]],
        password_confirmation: [''],
        current_password: ['', [Validators.required, Validators.minLength(6)]],
      },
    );

    this
      .userService
      .profile$
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(
        (profile: Profile) => {
          this.form.controls.email.setValue(profile.email);
        },
      );

    this
      .form
      .valueChanges
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.errors = [];
      });
  }

  closeModal(): void {
    this.dialogRef.close();
  }

}
