import {
  Component, ElementRef, EventEmitter, Input, Output, ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';


import { CropAvatarComponent, CropParams } from '@commons/modals/crop-avatar/crop-avatar.component';
import { IqModalService } from '@commons/iq-modal/iq-modal.service';

@UntilDestroy()
@Component({
  selector: 'iq-form-control-crop-img',
  templateUrl: './iq-form-control-crop-img.component.html',
  styleUrls: ['./iq-form-control-crop-img.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: IqFormControlCropImgComponent,
      multi: true,
    },
  ],
})
export class IqFormControlCropImgComponent implements ControlValueAccessor {

  @ViewChild('nativeInput') nativeInput: ElementRef;

  @Input() fileName: string = null;

  @Input() action$: Subscription;

  @Input() cropParams: Omit<CropParams, 'imageChangedEvent'>;

  @Output() removeEvent: EventEmitter<any> = new EventEmitter();

  file: File | null = null;

  onChange: any = () => {};

  onTouched: any = () => {};

  constructor(
    private iqModalService: IqModalService,
  ) {}

  writeValue(): void {
    this.file = null;
  }

  registerOnChange(callback: any): void {
    this.onChange = callback;
  }

  registerOnTouched(callback: any): void {
    this.onTouched = callback;
  }

  onImageSelected(event: Event): void {
    const target: HTMLInputElement = event.target as HTMLInputElement;

    if (target.files && target.files.length > 0) {
      const fileName: string = `${(Math.ceil(event.timeStamp))}_${target.files[0].name}`;

      this
        .iqModalService
        .open(
          CropAvatarComponent,
          {
            ...this.cropParams,
            imageChangedEvent: event,
          },
          { position: 'top', disableClose: true },
        )
        .closed
        .pipe(
          tap(() => {
            this.nativeInput.nativeElement.value = null;
          }),
          untilDestroyed(this),
        )
        .subscribe((image: Blob) => {
          if (image) {
            const file: File = new File([image], fileName, { type: image.type });

            this.file = file;
            this.onChange(file);
          }
        });
    }
  }

  remove(): void {
    if (this.file) {
      this.file = null;
    } else if (this.fileName) {
      this.removeEvent.emit();
    }

    this.onChange(null);
  }

}
