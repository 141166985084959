import { Component } from '@angular/core';

import type { ConfirmActionModalCopy } from '@core/types';

// Consts
import { COMMON_MODAL_TYPE } from '@consts/consts';
import { DialogRef } from '@angular/cdk/dialog';

@Component({
  templateUrl: './confirm-action-common.component.html',
  styleUrls: ['./confirm-action-common.component.scss'],
})
export class ConfirmActionCommonComponent {

  modalData: ConfirmActionModalCopy;

  contactsFee: string = COMMON_MODAL_TYPE.CONTACTS_FEE;

  constructor(
    private dialogRef: DialogRef,
  ) {
  }

  confirm(): void {
    this.closeModal(true);
  }

  closeModal(closeResult?: boolean): void {
    this.dialogRef.close(closeResult);
  }

}
