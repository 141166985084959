<div class="sidebar">
  <div class="modal-header">
    <div class="modal-header__title">Refinance opportunity</div>
    <div class="modal-close inline-svg"
         [inlineSVG]="'#icon-close'"
         (click)="closeSidebar()"></div>
  </div>
  <div class="modal-body"
       overflowShadow>
    <form [formGroup]="form"
          (ngSubmit)="submit()">
      <p class="change-rate__name m-t-5">{{ item?.owner_name }}</p>
      <p class="change-rate__address m-b-16">{{ item?.full_address }}</p>
      <p class="change-rate__text m-b-24">Preview, edit rate or send as is the quote. Homeowner will receive an email
        with all new rates and saving and your contact info. The PDF version of the quote will be attached as well.</p>
      <iq-form-control-percent class="m-b-1x"
                               label="New rate"
                               placeholder="0.0%"
                               formControlName="new_interest_rate"
                               [errorsModel]="formErrors.new_interest_rate"></iq-form-control-percent>
      <div class="f-12-normal f-c-blue-grey m-b-3x">
        Based on national rates. To edit default rates or add your margin&#32;
        <a [routerLink]="[ROUTE.alias.HOMEOWNERS_SETTINGS]"
           target="_blank">
          click here
        </a>
      </div>
      <div class="change-rate__info flex">
        <div class="flex-1">
          <p class="change-rate__info--title">New term</p>
          <p class="change-rate__info--value">{{ item?.loan_term }} years</p>
        </div>
        <div class="change-rate__divider m-r-48"></div>
        <div class="flex-1">
          <p class="change-rate__info--title">New APR</p>
          <p class="change-rate__info--value">{{ item?.apr }}%</p>
        </div>
        <div class="change-rate__divider m-r-48"></div>
        <div class="flex-1">
          <p class="change-rate__info--title">Monthly payment</p>
          <p class="change-rate__info--value">{{ item?.monthly_payment | amount }}</p>
        </div>
      </div>
      <div class="change-rate__savings m-b-24">
        <p class="change-rate__savings--title m-b-8">Savings</p>
        <p class="change-rate__savings--value">
          <span>${{ item?.monthly_savings }}</span>/mo
        </p>
      </div>
      <div class="change-rate__pdf m-b-24">
        <a *ngIf="!isDownloading"
           (click)="download()">

          <div class="change-rate__pdf--icon inline-svg m-r-8"
               [inlineSVG]="'#icon-eye'"></div>
          <span>Download PDF Quote</span>
        </a>
        <loading-dots [ngClass]="{ 'visible-dots' : isDownloading }"></loading-dots>
      </div>
      <iq-button class="block m-b-16"
                 label="Send Quote"
                 type="button"
                 (onClick)="sendQuote()"></iq-button>
      <div class="flex">
        <div class="flex-1 m-r-8">
          <iq-button class="block m-b-10"
                     label="Cancel"
                     type="button"
                     (onClick)="closeSidebar()"
                     color="grey"
                     [style]="'outline'"></iq-button>
        </div>
        <div class="flex-1 m-l-8">
          <iq-button class="block m-b-10"
                     label="Save"
                     type="submit"
                     [disabled]="form.pristine || form.invalid"
                     [busy$]="action$"
                     [style]="'outline'"></iq-button>
        </div>
      </div>
    </form>
  </div>
</div>
