import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ResponseErrorHandler, FormErrors } from 'asap-team/asap-tools';

import type { APIErrorResponse } from '@core/types';

// Consts
import { COMMON_TOAST, confirmPhoneErrors } from '@consts/consts';

// Services
import { UserService } from '@core/services/user/user.service';
import { DialogRef } from '@angular/cdk/dialog';
import { TypedFormGroup } from '@core/types/form-group-config.type';

@UntilDestroy()
@Component({ templateUrl: './confirm-phone.component.html' })
export class ConfirmPhoneComponent implements OnInit {

  confirmPhone$: Subscription;

  resendCode$: Subscription;

  formErrors: FormErrors = confirmPhoneErrors;

  form: FormGroup;

  constructor(
    private builder: FormBuilder,
    private toastr: ToastrService,
    private userService: UserService,
    private dialogRef: DialogRef,
    private responseErrorHandler: ResponseErrorHandler,
  ) {
  }

  ngOnInit(): void {
    this.form = this.builder.group<TypedFormGroup<{ code: string }>>({ code: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]] });
    this.subscribes();
  }

  subscribes(): void {
    this
      .userService
      .sendPhoneConfirmation()
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(
        () => {
          this.toastr.success(COMMON_TOAST.CONFIRMATION_CODE_SENT);
        },
      );

    this
      .form
      .valueChanges
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.formErrors.global = [];
      });
  }

  confirmPhone(): void {
    const { form } = this;
    const { code } = this.form.value;

    this.confirmPhone$ = this
      .userService
      .confirmPhone(code)
      .pipe(
        untilDestroyed(this),
      )
      .subscribe({
        next: () => this.closeModal(true),
        error: (error: APIErrorResponse) => this.responseErrorHandler.process(error, form, this.formErrors),
      });
  }

  resendCode(): void {
    this.resendCode$ = this
      .userService
      .sendPhoneConfirmation()
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(
        () => {
          this.toastr.success(COMMON_TOAST.CONFIRMATION_CODE_SENT);
        },
      );
  }

  closeModal(closeResult?: boolean): void {
    this.dialogRef.close(closeResult);
  }

}
