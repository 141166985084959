<div class="modal-content">
    <div class="modal-header">
        <h4 class="modal-title">Checkout</h4>
        <div class="close inline-svg" aria-label="Close" [inlineSVG]="'#icon-close'" (click)="closeModal()"></div>
    </div>
    <div class="modal-body">
        <img class="modal-body__icon" src="assets/common/dialog/credit-card-success.svg"/>
        <div class="modal-body__title">Success!</div>
        <div class="modal-body__text">We received your payment. Order complete.</div>
    </div>
    <div class="modal-footer text-center">
        <iq-button label="Ok" (onClick)="closeModal()"></iq-button>
    </div>
</div>
