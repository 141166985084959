import { Component } from '@angular/core';

import type { ConfirmActionModalCopy } from '@core/types';
import { DialogRef } from '@angular/cdk/dialog';

@Component({ templateUrl: './confirm-action-rare.component.html' })
export class ConfirmActionRareComponent {

  modalData: ConfirmActionModalCopy;

  constructor(
    private dialogRef: DialogRef,
  ) {
  }

  confirm(): void {
    this.closeModal(true);
  }

  closeModal(closeResult?: boolean): void {
    this.dialogRef.close(closeResult);
  }

}
