import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import momentMini from 'moment-mini';

import type { IntegrationDetails } from '@core/types';

@UntilDestroy()
@Component({
  selector: 'i-list-form',
  templateUrl: './i-list-form.component.html',
  styleUrls: ['./i-list-form.component.scss'],
})
export class IListFormComponent {

  @Input() iList: IntegrationDetails;

  @Input() connected: boolean;

  @Input() isReadyToConnect: boolean;

  @Output() activateIntegration: EventEmitter<void> = new EventEmitter<void>();

  @Output() disableIntegration: EventEmitter<void> = new EventEmitter<void>();

  @Output() generateNewKey: EventEmitter<void> = new EventEmitter<void>();

  handleGenerateNewKey(): void {
    this.generateNewKey.emit();
  }

  handleActivateIntegration(): void {
    this.activateIntegration.emit();
  }

  handleDisableIntegration(): void {
    this.disableIntegration.emit();
  }

  get lastActivityOlderThanMonth(): boolean {
    const connectDate: momentMini.Moment = momentMini(this.iList.last_activity_at);
    const todayDate: momentMini.Moment = momentMini();

    return connectDate.diff(todayDate, 'days') >= 30;
  }

}
