<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">Check your mailbox</h4>
    <div class="close inline-svg" aria-label="Close" [inlineSVG]="'#icon-close'" (click)="closeModal()"></div>
  </div>
  <div class="modal-body"><img class="modal-body__icon" src="assets/images/invitation-sent.svg"/>
    <div class="modal-body__text">
      We’ve sent you a confirmation link to <div class="bold">{{ (profile$ | async)?.email }}</div>
      Check your inbox or spam folder.
    </div>
  </div>
  <div class="modal-footer text-center">
    <iq-button class="block" label="Got it" (onClick)="closeModal()"></iq-button>
    <iq-button class="block m-t-2x"
               label="Change email"
               color="blue"
               [style]="'outline'"
               (onClick)="goToProfile()"></iq-button>
  </div>
</div>
