export const LeadActivityModules: any = {
  not_implemented: {
    load: (): any => import('../common/not-implemented/not-implemented.component')
      .then((c: any) => c.NotImplementedComponent),
  },
  // 10
  lead_created: {
    load: (): any => import('../lead-activities/lead-created/lead-created.component')
      .then((c: any) => c.LeadCreatedComponent),
    icon: '💡',
  },
  // 20
  lead_imported: {
    load: (): any => import('../lead-activities/lead-created/lead-created.component')
      .then((c: any) => c.LeadCreatedComponent),
    icon: '💡',
  },
  // 30
  lead_created_from_traffic: {
    load: (): any => import('../lead-activities/lead-created/lead-created.component')
      .then((c: any) => c.LeadCreatedComponent),
    icon: '💡',
  },
  // 31
  lead_created_from_seller_traffic_stepper: {
    load: (): any => import('../lead-activities/lead-created-from-seller-traffic-stepper/lead-created-from-seller-traffic-stepper.component')
      .then((c: any) => c.LeadCreatedFromSellerTrafficStepperComponent),
    icon: '💡',
  },
  // 50
  lead_created_from_viral: {
    load: (): any => import('../lead-activities/lead-created/lead-created.component')
      .then((c: any) => c.LeadCreatedComponent),
    icon: '💡',
  },
  // 40
  lead_created_from_mls: {
    load: (): any => import('../lead-activities/lead-created/lead-created.component')
      .then((c: any) => c.LeadCreatedComponent),
    icon: '💡',
  },
  // 480
  lead_archived: {
    load: (): any => import('../lead-activities/lead-created/lead-created.component')
      .then((c: any) => c.LeadCreatedComponent),
    icon: '🗄',
  },
  // 490
  lead_restored: {
    load: (): any => import('../lead-activities/lead-created/lead-created.component')
      .then((c: any) => c.LeadCreatedComponent),
    icon: '🗄',
  },
  // 550
  lead_member_removed: {
    load: (): any => import('../lead-activities/lead-created/lead-created.component')
      .then((c: any) => c.LeadCreatedComponent),
    icon: '👥',
  },
  // 560
  lead_member_leaved: {
    load: (): any => import('../lead-activities/lead-created/lead-created.component')
      .then((c: any) => c.LeadCreatedComponent),
    icon: '👥',
  },
  // 520
  lead_member_assigned: {
    load: (): any => import('../lead-activities/lead-created/lead-created.component')
      .then((c: any) => c.LeadCreatedComponent),
    icon: '👥',
  },
  // 510
  lead_member_autoassigned: {
    load: (): any => import('../lead-activities/lead-created/lead-created.component')
      .then((c: any) => c.LeadCreatedComponent),
    icon: '👥',
  },
  // 31, 535
  lead_member_assigned_from_mls: {
    load: (): any => import('../lead-activities/lead-created/lead-created.component')
      .then((c: any) => c.LeadCreatedComponent),
    icon: '👥',
  },
  lead_email_not_delivered: {
    load: (): any => import('../lead-activities/lead-email-not-delivered/lead-email-not-delivered.component')
      .then((c: any) => c.LeadEmailNotDeliveredComponent),
    icon: '✉️',
  },
  // 230
  user_update_property_details: {
    load: (): any => import('../lead-activities/user-update-loan-info/user-update-loan-info.component')
      .then((c: any) => c.UserUpdateLoanInfoComponent),
    icon: '🏠',
  },
  // 235
  lead_property_details_changed: {
    load: (): any => import('../common/system-activity/system-activity.component')
      .then((c: any) => c.SystemActivityComponent),
    icon: '🏠',
  },
  // 423
  system_update_loan_info: {
    load: (): any => import('../common/system-activity/system-activity.component')
      .then((c: any) => c.SystemActivityComponent),
    icon: '🏠',
  },
  // 440
  seller_digest_verify_cma: {
    load: (): any => import('../lead-activities/lead-created/lead-created.component')
      .then((c: any) => c.LeadCreatedComponent),
    icon: '💰',
  },
  // 160
  seller_digest_client_subscribe: {
    load: (): any => import('../lead-activities/seller-digest-client-subscribe/seller-digest-client-subscribe.component')
      .then((c: any) => c.SellerDigestClientSubscribeComponent),
    icon: '🏄',
  },
  // 220
  seller_digest_client_unsubscribe: {
    load: (): any => import('../lead-activities/seller-digest-client-subscribe/seller-digest-client-subscribe.component')
      .then((c: any) => c.SellerDigestClientSubscribeComponent),
    icon: '✖',
  },
  // 150
  seller_digest_user_activate: {
    load: (): any => import('../lead-activities/seller-digest-user-activate/seller-digest-user-activate.component')
      .then((c: any) => c.SellerDigestSentActivateComponent),
    icon: '🏄',
  },
  // 170
  seller_digest_user_deactivate: {
    load: (): any => import('../lead-activities/seller-digest-user-activate/seller-digest-user-activate.component')
      .then((c: any) => c.SellerDigestSentActivateComponent),
    icon: '✖',
  },
  // 70
  seller_digest_sent_email: {
    load: (): any => import('../common/system-activity/system-activity.component')
      .then((c: any) => c.SystemActivityComponent),
    icon: '✉️',
  },
  // 75
  seller_digest_sent_auto: {
    load: (): any => import('../common/system-activity/system-activity.component')
      .then((c: any) => c.SystemActivityComponent),
    icon: '✉️',
  },
  // 80
  seller_digest_sent_sms: {
    load: (): any => import('../common/system-activity/system-activity.component')
      .then((c: any) => c.SystemActivityComponent),
    icon: '📱',
  },
  // 95
  seller_digest_sent_manual: {
    load: (): any => import('../lead-activities/user-update-loan-info/user-update-loan-info.component')
      .then((c: any) => c.UserUpdateLoanInfoComponent),
    icon: '✉️',
  },
  // 110
  lead_invalid_contact: {
    load: (): any => import('../lead-activities/lead-invalid-contact/lead-invalid-contact.component')
      .then((c: any) => c.LeadInvalidContactComponent),
  },
  // 310
  user_update_loan_info: {
    load: (): any => import('../lead-activities/user-update-loan-info/user-update-loan-info.component')
      .then((c: any) => c.UserUpdateLoanInfoComponent),
    icon: '🏠',
  },
  // 370
  client_update_loan_info: {
    load: (): any => import('../lead-activities/client-update-loan-info/client-update-loan-info.component')
      .then((c: any) => c.ClientUpdateLoanInfoComponent),
  },
  // 295
  client_update_homeowner_name: {
    load: (): any => import('../lead-activities/client-update-homeowner-name/client-update-homeowner-name.component')
      .then((c: any) => c.ClientUpdateHomeownerNameComponent),
  },
  // 291
  client_update_homeowner_details: {
    load: (): any => import('../lead-activities/client-update-homeowner-details/client-update-homeowner-details.component')
      .then((c: any) => c.ClientUpdateHomeownerNameComponent),
  },
  // 430
  seller_digest_request_cma: {
    load: (): any => import('../lead-activities/seller-digest-request-cma/seller-digest-request-cma.component')
      .then((c: any) => c.SellerDigestRequestCmaComponent),
  },
  // 450
  seller_digest_ask_question: {
    load: (): any => import('../lead-activities/seller-digest-ask-question/seller-digest-ask-question.component')
      .then((c: any) => c.SellerDigestAskQuestionComponent),
  },
  // 240
  updating_homeowner_details: {
    load: (): any => import('../lead-activities/updating-homeowner-details/updating-homeowner-details.component')
      .then((c: any) => c.UpdatingHomeownerDetailsComponent),
  },
  // 260
  updating_homeowner_name: {
    load: (): any => import('../global-activities/updating-homeowner-name/updating-homeowner-name.component')
      .then((c: any) => c.UpdatingHomeownerNameComponent),
  },
  // 140
  scheduled_cma_updated_failed: {
    load: (): any => import('../lead-activities/scheduled-cma-updated-failed/scheduled-cma-updated-failed.component')
      .then((c: any) => c.ScheduledCmaUpdatedFailedComponent),
  },
  // 440+
  avm_automatically_updated: {
    load: (): any => import('../lead-activities/iq-lead-activity/iq-lead-activity.component')
      .then((c: any) => c.IqLeadActivityComponent),
  },
  // 440+
  fees_automatically_updated: {
    load: (): any => import('../lead-activities/iq-lead-activity/iq-lead-activity.component')
      .then((c: any) => c.IqLeadActivityComponent),
  },
};
