export const FEATURES: Record<string, string> = {
  TRAINING_VIDEO_GUIDES: 'training_video_guides',
  TRAINING_GROWING_YOUR_BUSINESS: 'training_growing_your_business',
  TRAINING_REALTOR_CONNECTION_KIT: 'training_realtor_connection_kit',
  TRAINING_COACHING: 'training_coaching',
  TRAINING_MARKETING_FLYERS: 'training_marketing_flyers',
  INTELLIGENCE: 'intelligence',
  NEW_REPORT_FLOW: 'new_report_flow',
  SMS_NOTIFICATIONS: 'smsNotifications',
};
