import type { NavItem } from '@core/types';

import { ROUTE, COLLECTION_COUNTER_NAME, PERMITTED_ACTION } from '@consts/consts';

export const Buyers: NavItem[] = [
  {
    label: 'Leads',
    routerLink: ROUTE.alias.BUYERS_TRAFFIC,
    counter: COLLECTION_COUNTER_NAME.BUYER_TRAFFIC,
    roles: null,
    permitted_actions: [PERMITTED_ACTION.BUYERS_VIEW],
    icon: null,
  },
];
