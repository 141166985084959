import { NgModule } from '@angular/core';
import { OverlayModule } from '@angular/cdk/overlay';

@NgModule({
  imports: [
    OverlayModule,
  ],
})
export class IqSidebarModule {
}
