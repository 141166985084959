import { Observable, Subscription } from 'rxjs';
import { Component } from '@angular/core';

import type { PaymentMethod } from '@core/types';

// Services
import { BillingService } from '@core/services/billing/billing.service';
import { DialogRef } from '@angular/cdk/dialog';

@Component({ templateUrl: './delete-card.component.html' })
export class DeleteCardComponent {

  card: PaymentMethod;

  action: Subscription;

  constructor(
    private billingService: BillingService,
    private dialogRef: DialogRef,
  ) {
  }

  submit(): void {
    const observable: Observable<any> = this.billingService.deletePaymentMethod(this.card.id);

    this.action = observable.subscribe(
      () => {
        this.billingService.paymentMethodsChange();
        this.closeModal(true);
      },
    );
  }

  closeModal(closeResult?: boolean): void {
    this.dialogRef.close(closeResult);
  }

}
