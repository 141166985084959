<div class="modal-content">
  <div class="modal-header modal-hide-caret">
    <h4 class="modal-title">LendingPad Integration</h4>
    <div class="close inline-svg" aria-label="Close" [inlineSVG]="'#icon-close'" (click)="closeModal()"></div>
  </div>
  <div class="modal-body">
    <i class="block inline-svg--80 m-b-2x" [inlineSVG]="'#icon-lending_pad-logo'"></i>
    <div class="modal-body__text m-b-4x">
      LendingPad can only be connected by the myhomeIQ team. Please email <a href="mailto:support@myhomeiq.com">support@myhomeiq.com</a> to request.
    </div>
  </div>
</div>
