import { Injectable } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { pickBy } from 'lodash-es';
import { Observable, identity } from 'rxjs';
import { Dictionary, BaseHttpService } from 'asap-team/asap-tools';

// Consts
import {
  TRACKING_ID_TYPE,
  FB_TRACKING_ID_PATTERN,
  GA_TRACKING_ID_PATTERN,
  GOOGLE_ADWORDS_PATTERN,
  GOOGLE_TAG_MANAGER_PATTERN,
} from '@consts/consts';

@Injectable({ providedIn: 'root' })
export class SettingsService {

  constructor(
    private fb: FormBuilder,
    private http: BaseHttpService,
  ) {}

  updateSettings(form: FormGroup): Observable<void> {
    const params: Partial<any> = pickBy(form.value, identity);

    return this
      .http
      .patch('v2/settings', params, { observe: 'response' });
  }

  createTrackingIdFormControl(form: FormGroup, type: string, value: string): void {
    switch (type) {
      case TRACKING_ID_TYPE.FACEBOOK_PIXEL: {
        form.addControl('id', this.fb.control(value, [Validators.pattern(FB_TRACKING_ID_PATTERN)]));
        break;
      }
      case TRACKING_ID_TYPE.GOOGLE_ANALYTICS: {
        form.addControl('id', this.fb.control(value, [Validators.pattern(GA_TRACKING_ID_PATTERN)]));
        break;
      }
      case TRACKING_ID_TYPE.GOOGLE_ADWORDS: {
        form.addControl('id', this.fb.control(value, [Validators.pattern(GOOGLE_ADWORDS_PATTERN)]));
        break;
      }
      case TRACKING_ID_TYPE.GOOGLE_TAG_MANAGER: {
        form.addControl('id', this.fb.control(value, [Validators.pattern(GOOGLE_TAG_MANAGER_PATTERN)]));
        break;
      }
      default: {
        break;
      }
    }
  }

  saveTrackingId(apiUrl: string, value: Dictionary): Observable<any> {
    return this
      .http
      .patch(apiUrl, value);
  }

  updateIdxLink(idx_link: string | null): Observable<any> {
    return this
      .http
      .patch('v2/buyer_traffic/landing/idx_link', { idx_link });
  }

}
