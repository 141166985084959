import { Injectable } from '@angular/core';
import { CanActivate, Router, CanLoad } from '@angular/router';
import { map, take } from 'rxjs/operators';

import type { Observable } from 'rxjs';
import type { Profile } from '@core/types';

// Consts
import { PROFILE_STATUS, ROUTE } from '@consts/consts';

// Services
import { UserService } from '@core/services/user/user.service';

@Injectable({ providedIn: 'root' })
export class AccountStatusGuard implements CanActivate, CanLoad {

  constructor(
    private router: Router,
    private userService: UserService,
  ) {}

  private doCheck(): Observable<boolean> {
    return this
      .userService
      .profile$
      .pipe(
        take(1),
        map(({ status, blocked_type }: Profile) => {
          const blockType: string = this.getBlockType(status, blocked_type);

          const isAccessRestricted: boolean = status === PROFILE_STATUS.BLOCKED || status === PROFILE_STATUS.SUSPEND;

          if (isAccessRestricted) {
            this.router.navigate([ROUTE.name.RESTRICTED_ACCESS], { state: { blocked_type: blockType } });

            return false;
          }

          return true;
        }),
      );
  }

  canLoad(): Observable<boolean> {
    return this.doCheck();
  }

  canActivate(): Observable<boolean> {
    return this.doCheck();
  }

  private getBlockType(status: string, blocked_type: string): string {
    return status === PROFILE_STATUS.SUSPEND ? 'suspend' : blocked_type;
  }

}
