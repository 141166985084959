import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { switchMap } from 'rxjs/operators';


import type { Alert, Profile } from '@core/types';

// Consts
import { ROUTE } from '@consts/consts';

// Components
import { ConfirmEmailModalComponent } from '@commons/modals/confirm-email/confirm-email-modal.component';

// Services
import { AlertsService } from '@core/services/alerts/alerts.service';
import { UserService } from '@core/services/user/user.service';
import { IqModalService } from '@commons/iq-modal/iq-modal.service';

@UntilDestroy()
@Component({
  templateUrl: './confirm-email.component.html',
  styleUrls: ['../../alerts.component.scss'],
})
export class ConfirmEmailComponent {

  @Input() type: Alert;

  ROUTE: any = ROUTE;

  profile$: Observable<Profile> = this.userService.profile$;

  constructor(
    private alertsService: AlertsService,
    private userService: UserService,
    private iqModalService: IqModalService,
  ) {}

  closeAlert(type: Alert): void {
    this.alertsService.dismiss(type);
  }

  resendConfirmationEmail(): void {
    this
      .userService
      .requestConfirmEmail()
      .pipe(
        switchMap(() => this.iqModalService.open(ConfirmEmailModalComponent).closed),
        untilDestroyed(this),
      )
      .subscribe();
  }

}
