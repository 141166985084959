import { Component } from '@angular/core';
import { CdkDialogContainer } from '@angular/cdk/dialog';

@Component({
  selector: 'iq-modal-container',
  templateUrl: './iq-modal-container.component.html',
  host: { class: 'iq-modal' },
})
export class IqModalContainerComponent extends CdkDialogContainer {
}
