import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService } from 'asap-team/asap-tools';

import { CollectionStaffUser, RoundRobinTeamType, RoundRobinTeamUser } from '@core/types';

@Injectable({ providedIn: 'root' })
export class StaffUserService {

  constructor(
    private http: BaseHttpService,
    private httpClient: HttpClient,
  ) {}

  private buildUrl(company_code: string, id?: string): string {
    return [
      'v2/admin',
      (!!company_code && company_code !== 'home-iq') ? `companies/${company_code}/staff` : 'staff',
      id || null,
    ]
      .filter(Boolean)
      .join('/');
  }

  createStaffUser(payload: any, company_code?: string): Observable<any> {
    const formData: FormData = new FormData();

    Object.keys(payload).forEach((key: string) => {
      formData.append(key, payload[key] as string | Blob);
    });

    return this.http.post(this.buildUrl(company_code), formData);
  }

  deleteStaffUser(id: string, company_code?: string): Observable<any> {
    return this.http.delete(this.buildUrl(company_code, id));
  }

  getStaffUserDetails(id: string, company_code?: string): Observable<CollectionStaffUser> {
    return this.http.get(this.buildUrl(company_code, id));
  }

  updateStaffUserDetails(id: string, payload: any, company_code?: string): Observable<any> {
    const formData: FormData = new FormData();

    Object.keys(payload).forEach((key: string) => {
      formData.append(key, payload[key] as string | Blob);
    });

    return this.http.put(this.buildUrl(company_code, id), formData);
  }

  changeStaffAvatar(id: string, { image, name }: any, company_code?: string): Observable<void> {
    const url: string = this.buildUrl(company_code, id);
    const formData: FormData = new FormData();

    formData.append('avatar', image, name);

    return this.http.patch(`${url}/avatar`, formData);
  }

  getRoundRobinUsers(type: RoundRobinTeamType): Observable<RoundRobinTeamUser[]> {
    return this.httpClient.get<RoundRobinTeamUser[]>(`v2/admin/staff/lead_distribution/${type}`);
  }

  updateRoundRobinUsers(type: RoundRobinTeamType, payload: { id: string; round_robin_weight: string }[]): Observable<RoundRobinTeamUser[]> {
    return this.httpClient
      .patch<RoundRobinTeamUser[]>(`v2/admin/staff/lead_distribution/${type}`, { lead_distributions: payload });
  }

}
