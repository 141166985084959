import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormError } from 'asap-team/asap-tools';
import { TypedFormGroup } from '@core/types/form-group-config.type';

@UntilDestroy()
@Component({
  selector: 'iq-form-control-percent',
  templateUrl: './iq-form-control-percent.component.html',
  styleUrls: ['./iq-form-control-percent.component.scss'],
  // providers: provideRefs(IqFormControlPercentComponent), Return to this line after ng17 and update of ASAP-tools forwardRefs
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IqFormControlPercentComponent),
      multi: true,
    },
  ],
})
export class IqFormControlPercentComponent implements ControlValueAccessor, OnInit {

  @ViewChild('nativeInput', { static: true, read: ElementRef }) nativeInput: ElementRef<HTMLInputElement>;

  @Input() label: string | null = null;

  @Input() labelStyle: 'wide' | null = null;

  @Input() lpignore: boolean = true;

  @Input() errorsModel: FormError[];

  @Input() formControlName: string;

  @Input() readonly: boolean = false;

  @Input() customMask: string = null;

  @Input() placeholder: string = '0.000%';

  form: FormGroup;

  focused: boolean = false;

  // Temp patterns
  // Wait merge PR where will add ability input negative value: https://github.com/JsDaddy/ngx-mask/pull/630
  negativePatterns: any = {
    M: { pattern: /-/ },
    P: { pattern: /\./ },
    0: { pattern: /[0-9]/ },
  };

  constructor(
    private renderer2: Renderer2,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group<TypedFormGroup<{ value: string }>>({ value: [''] });

    this
      .form
      .controls
      .value
      .valueChanges
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((value: any) => {
        this.onChange(value);
      });
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  writeValue(value: string): void {
    this.form.patchValue({ value });
  }

  registerOnChange(callback: any): void {
    this.onChange = callback;
  }

  registerOnTouched(callback: any): void {
    this.onTouched = callback;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer2.setProperty(this.nativeInput.nativeElement, 'disabled', isDisabled);
  }

  focus(): void {
    if (this.readonly) { return; }

    this.focused = true;
  }

  blur(): void {
    this.focused = false;
    this.onTouched();
  }

  getNativeElementRef(): ElementRef<HTMLButtonElement> {
    return this.nativeInput;
  }

}
