<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">Invitation email</h4>
    <div class="close inline-svg" aria-label="Close" [inlineSVG]="'#icon-close'" (click)="closeModal()"></div>
  </div>
  <div class="modal-body">
    <div class="process" [ngSwitch]="step">
      <h2 class="process-title f-12-normal f-c-slate">Step {{ step }} of 3</h2>
      <p class="f-16-normal f-c-gray-main" *ngSwitchCase="1">
        After you send the invite, your <span *roleIf="USER_ROLE.agent"> loan officer </span>
        <span *roleIf="USER_ROLE.lender">agent will receive an email that positions you as the hero introducing them
          to <i>my</i>homeIQ
        </span>
      </p>
      <p class="f-16-normal f-c-gray-main" *ngSwitchCase="2">
        Once the<span *roleIf="USER_ROLE.agent"> loan officer</span><span *roleIf="USER_ROLE.lender"> agent clicks on
        the email to learn more, they will be redirected to a page with a video that explains <i>my</i>homeIQ
        more</span>
      </p>
      <p class="f-16-normal f-c-gray-main" *ngSwitchCase="3">
        <span *roleIf="USER_ROLE.agent">
          When the loan officer activates their account and accepts the invitation, they will be connected with you which will position you as the preferred agent for all their past clients.
        </span>
        <span *roleIf="USER_ROLE.lender">
          When the agent activates their account, they will be exclusively connected with you which will position you as the preferred lender for all their past clients
        </span>
      </p>
      <div class="process-screen"
           [inlineSVG]="'/assets/components/invite-partner/process/' + step + '.svg'"
           [resolveSVGUrl]="false"></div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row row-fix">
      <div class="col col-fix">
        <iq-button class="block"
                   label="{{ step === 1 ? 'Cancel' : 'Prev' }}"
                   color="grey"
                   [style]="'outline'"
                   (click)="changeStep(step, 'prev')"></iq-button>
      </div>
      <div class="col col-fix">
        <iq-button class="block"
                   label="{{ step === 3 ? 'Ok, Got It' : 'Next' }}"
                   (click)="changeStep(step, 'next')"></iq-button>
      </div>
    </div>
  </div>
</div>
