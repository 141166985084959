import { Component } from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';

@Component({ templateUrl: './checkout-error.component.html' })
export class CheckoutErrorComponent {

  error: string;

  constructor(
    private dialogRef: DialogRef,
  ) {
  }

  confirm(): void {
    this.closeModal(true);
  }

  closeModal(closeResult?: boolean): void {
    this.dialogRef.close(closeResult);
  }

}
