<div class="sign-up-content">
  <form
    class="sign-form"
    [formGroup]="form"
    (ngSubmit)="signUp()"
  >
    <h1 class="sign-form__title">Sign Up</h1>
    <div class="sign-form__avatar">
      <user-avatar
        role="lender"
        canEdit="true"
        [avatar]="imageUrl"
        (imageSelected)="addAvatar($event)"
      ></user-avatar>
    </div>
    <div class="sign-form__avatar-help-text">
      <span>Don’t forget to upload your photo.</span>
    </div>
    <iq-form-control-text
      class="m-b-2x"
      label="Name*"
      placeholder="Name"
      formControlName="name"
      [errorsModel]="formErrors.name"
    ></iq-form-control-text>
    <iq-form-control-text
      class="m-b-2x"
      type="email"
      label="Email*"
      placeholder="Email"
      [lpignore]="false"
      formControlName="email"
      [errorsModel]="formErrors.email"
    ></iq-form-control-text>
    <iq-form-control-phone
      class="m-b-2x"
      label="Cell Phone*"
      formControlName="phone"
      [errorsModel]="formErrors.phone"
    ></iq-form-control-phone>
    <iq-form-control-password
      class="m-b-2x"
      label="Create Password*"
      placeholder="********"
      formControlName="password"
      [errorsModel]="formErrors.password"
    ></iq-form-control-password>
    <h2 class="sign-form__subtitle">License</h2>
    <iq-form-control-text
      class="m-b-2x"
      label="NMLS Number*"
      placeholder="123456789"
      formControlName="license_number"
      [errorsModel]="formErrors.license_number"
    ></iq-form-control-text>
    <iq-form-control-custom-multiselect
      class="m-b-2x"
      label="State Licensed"
      dropup="true"
      placeholder="Select licensed states"
      filterPlaceholder="Type state name"
      [displayCode]="true"
      [model]="states"
      formControlName="license_states"
      [errorsModel]="formErrors.license_states"
    ></iq-form-control-custom-multiselect>
    <h4 class="f-16-bold f-c-slate m-t-2x m-b-10">
      Time zone
    </h4>
    <p class="f-14-400 f-c-steel m-b-10">Your current time zone. Used to send summary and notification emails, for times in your activity feeds, and for reminders.</p>
    <iq-form-control-custom-select
      class="m-b-5x"
      label="Time zone"
      placeholder="Choose timezone"
      formControlName="time_zone"
      [model]="(timeZones$ | async)"
      [errorsModel]="formErrors.time_zone"
    ></iq-form-control-custom-select>
    <div *ngFor="let error of formErrors.global" class="control-error-message m-b-1x">
      {{ error }}
    </div>
    <div class="m-t-3x text-center">
      <iq-button
        class="block m-b-3x"
        type="submit"
        label="Sign Up"
        size="56"
        [busy$]="action$"
        [disabled]="form.invalid"
      ></iq-button>
    </div>
    <div class="sign-form__description">
      By clicking Complete Profile, you agree to our
      <a [href]="termsConditionsLink" class="link">Terms of Use</a>
      and
      <a [href]="privacyPolicyLink" class="link">Privacy policy</a>.
      If you already have account, you can
      <a [routerLink]="[ROUTE.alias.SIGN_IN]" class="link">Sign In</a>
    </div>
    <hr class="sign-form__line">
    <img class="sign-form__logo" src="/assets/common/logo/powered-by-myhomeiq-logo.svg">
  </form>
</div>
<div class="sign-preview f-14-500">
  <div class="sign-preview__image">
    <img src="/assets/components/sign-up/hand-with-phone.png">
  </div>
</div>
