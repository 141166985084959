import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

// Types
import type { UserBanner } from '@core/types';

// Services
import { IqModalService } from '@commons/iq-modal/iq-modal.service';

// Components
import { PromoBannerPreviewComponent } from '@commons/modals/promo-banner-preview/promo-banner-preview.component';

@Component({
  selector: 'promo-banner',
  templateUrl: './promo-banner.component.html',
  styleUrls: ['./promo-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromoBannerComponent {

  @Input() banner: UserBanner;

  @Input() type: string;

  constructor(
    private iqModalService: IqModalService,
  ) { }

  openPreview(banner: UserBanner): void {
    this.iqModalService.open(
      PromoBannerPreviewComponent,
      { modalData: { banner, type: this.type } },
      { autoFocus: false },
    );
  }

}
