import {
  Component,
  Inject,
  OnInit,
  AfterViewInit,
  ViewChild,
  OnDestroy,
  ElementRef,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CollectionStoreService, Pagination, IntersectionObserverService } from 'asap-team/asap-tools';
import { Observable } from 'rxjs';

// Types
import { Kudos, UserRole } from '@core/types';

// Consts
import { KUDOS_COLLECTION } from '@consts/injection-tokens';

// Services
import { KudosService } from '@core/services/collections/kudos/kudos.service';
import { IqSidebarService } from '@commons/iq-sidebar/iq-sidebar.service';

@UntilDestroy()
@Component({
  selector: 'app-rewards-sidebar',
  templateUrl: './rewards-sidebar.component.html',
  styleUrls: ['./rewards-sidebar.component.scss'],
})
export class RewardsSidebarComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('ghost') ghost: ElementRef<HTMLInputElement>;

  rows$: Observable<Kudos[]> = this.collectionService.rows$;

  pagination$: Observable<Pagination> = this.collectionService.pagination$;

  userRole: UserRole;

  constructor(
    @Inject(KUDOS_COLLECTION) private collectionService: CollectionStoreService<Kudos, KudosService>,
    private iqSidebarService: IqSidebarService,
    private intersectionObserverService: IntersectionObserverService,
  ) {
  }

  ngOnInit(): void {
    this.collectionService.load(0, 16);
  }

  ngAfterViewInit(): void {
    this.initScrollLoading();
  }

  ngOnDestroy(): void {
    this.collectionService.clearFilters(false);
  }

  private initScrollLoading(): void {
    this
      .intersectionObserverService
      .create({
        target: this.ghost.nativeElement,
        isOnlyIntersecting: true,
      })
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(
        () => {
          this.collectionService.loadMore();
        },
      );
  }

  closeSidebar(closeResult?: any): void {
    this.iqSidebarService.close(closeResult);
  }

}
