import { Route } from '@angular/router';

// Const
import { ROUTE } from '@consts/routes';
import { USER_ROLE } from '@consts/user';
import { AuthGuard } from '@core/guards/auth/auth.guard';
import { ProfileGuard } from '@core/guards/profile/profile.guard';
import { RoleGuard } from '@core/guards/role/role.guard';
import { RegistrationCompletedGuard } from '@core/guards/registration-completed/registration-completed.guard';
import { AccountStatusGuard } from '@core/guards/account-status/account-status.guard';

export const DASHBOARD: Route = {
  path: ROUTE.name.DASHBOARD,
  canActivate: [AuthGuard, ProfileGuard, RoleGuard, RegistrationCompletedGuard, AccountStatusGuard],
  canLoad: [AuthGuard, ProfileGuard, RoleGuard, RegistrationCompletedGuard, AccountStatusGuard],
  data: { canBeActivatedBy: [USER_ROLE.lender, USER_ROLE.agent] },
  loadChildren: () => import('@modules/user/dashboard/dashboard.module').then((m: any) => m.DashboardModule),
};
