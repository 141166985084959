import { Injectable, Type } from '@angular/core';
import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { IqModalContainerComponent } from '@commons/iq-modal/components/iq-modal-container/iq-modal-container.component';
import { IqModalConfig } from '@commons/iq-modal/models/iq-modal-config.model';
import { first } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class IqModalService {

  constructor(public dialog: Dialog) {
  }

  public open<T, R = any>(component: Type<T>, data: Partial<T> = {}, config: Partial<IqModalConfig> = {}): DialogRef<R, T> {
    const dialogRef: DialogRef<R, T> = this.dialog.open(component, {
      data,
      container: IqModalContainerComponent,
      backdropClass: 'iq-backdrop-no-bg',
      closeOnNavigation: true,
      closeOnDestroy: true,
      disableClose: false,
      hasBackdrop: true,
      panelClass: 'iq-modal',
      autoFocus: true,
      position: 'center',
      ...config,
    });

    // Set position of modal
    switch (config.position) {
      case 'top':
        dialogRef.addPanelClass('iq-modal-top');
        break;
      case 'left':
        dialogRef.addPanelClass('iq-modal-left');
        break;
      case 'right':
        dialogRef.addPanelClass('iq-modal-right');
        break;
      case 'bottom':
        dialogRef.addPanelClass('iq-modal-bottom');
        break;
      default:
        break;
    }

    // Map data fields on existing component`s props if match by key.
    if (data) {
      Object.keys(data).forEach((key: string) => dialogRef.componentInstance[key] = data[key]);
    }

    dialogRef.closed.pipe(first()).subscribe(this.unblockBodyScroll.bind(this));

    this.blockBodyScroll();

    return dialogRef;

  }

  public closeAll(): void {
    this.dialog.closeAll();
  }

  private blockBodyScroll(): void {
    document.body.style.overflow = 'hidden';
  }

  private unblockBodyScroll(): void {
    document.body.style.overflow = '';
  }

}

