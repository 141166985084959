import { Component, EventEmitter, Input, Output } from '@angular/core';
import type { IZapierDetails } from '@core/types';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'zapier-form',
  templateUrl: './zapier-form.component.html',
  styleUrls: ['./zapier-form.component.scss'],
})
export class ZapierFormComponent {

  @Input() zapierInvite: IZapierDetails;

  @Output() connect: EventEmitter<void> = new EventEmitter<void>();

  @Input() connected: boolean;

  @Output() disableIntegration: EventEmitter<void> = new EventEmitter<void>();

  isConnectButtonClicked: boolean = false;

  handleConnect(): void {
    this.isConnectButtonClicked = true;
    this.connect.emit();
  }

  handleDisableIntegration(): void {
    this.disableIntegration.emit();
  }

}
