import type { Dictionary } from 'asap-team/asap-tools';
import type { UserRole } from '@core/types';

export const USER_ROLE: Dictionary<UserRole> = {
  sales: 'sales',
  owner: 'owner',
  agent: 'agent',
  lender: 'lender',
  marketing: 'marketing',
  customer_success: 'customer_success',
  title_user: 'title_user',
};

export const USER_LABEL: Dictionary = {
  realtor: 'realtor',
  realtor_title: 'Realtor',
  agent: 'agent',
  agent_title: 'Agent',
  loan_officer: 'loan officer',
  loan_officer_title: 'Loan Officer',
};

export const REGISTRATION_STEP: Dictionary = {
  update_profile: 'update_profile',
  purchase: 'purchase',
  invite_lender: 'invite_lender',
  invite_sent: 'invite_sent',
  invite_external_user: 'invite_external_user',
  update_title_company: 'update_title_company',
  completed: 'completed',
};

export const FTL_STEP: Dictionary = {
  agent_welcome_video: 'agent_welcome_video',
  lender_welcome_video: 'lender_welcome_video',
  agent_closings: 'agent_closings',
  lender_closings: 'lender_closings',
  lost_closings: 'lost_closings',
  agent_lost_closings: 'agent_lost_closings',
  lender_lost_closings: 'lender_lost_closings',
  agent_pre_mover_closings: 'agent_pre_mover_closings',
  lender_pre_mover_closings: 'lender_pre_mover_closings',
  confirm_license: 'confirm_license',
  agent_explain_video: 'agent_explain_video',
  lender_explain_video: 'lender_explain_video',
  enter_home_address: 'enter_home_address',
  generate_report_failed: 'generate_report_failed',
  confirm_identity: 'confirm_identity',
  search_for_lender: 'search_for_lender',
  search_for_agent: 'search_for_agent',
  select_and_add_lender: 'select_and_add_lender',
  invite_primary_lender: 'invite_primary_lender',
  invite_agents: 'invite_agents',
  select_crm: 'select_crm',
  schedule_demo: 'schedule_demo',
  agent_welcome_step: 'agent_welcome_step',
  agent_seller_traffic: 'agent_seller_traffic',
  agent_buyer_traffic: 'agent_buyer_traffic',
  agent_seller_report: 'agent_seller_report',
  agent_enter_home_address: 'agent_enter_home_address',
  lender_welcome_step: 'lender_welcome_step',
  lender_partnership: 'lender_partnership',
  lender_seller_traffic: 'lender_seller_traffic',
  lender_buyer_traffic: 'lender_buyer_traffic',
  lender_seller_report: 'lender_seller_report',
  lender_enter_home_address: 'lender_enter_home_address',
  view_sample_report: 'view_sample_report',
  all_set: 'all_set',
};

export const FTL_FLOW: Dictionary = {
  REGULAR_FLOW: 'regular_flow',
  VIRAL_FLOW: 'viral_flow',
};

export const PROFILE_STATUS: Dictionary = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
  NEW_REQUEST: 'new_request',
  BLOCKED: 'blocked',
  SUSPEND: 'suspend',
};

export const BLOCKED_TYPE: Dictionary = {
  SUBSCRIPTION_EXPIRED_BLOCK: 'subscription_expired_block',
  SUSPEND: 'suspend',
  MANUAL_BLOCK: 'manual_block',
};

export const PREMIUM_FEATURES: Dictionary = { white_label: 'white_label' };
