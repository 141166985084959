import { Component } from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';

@Component({ templateUrl: './checkout-success.component.html' })
export class CheckoutSuccessComponent {

  constructor(
    private dialogRef: DialogRef,
  ) {
  }

  closeModal(): void {
    this.dialogRef.close();
  }

}
