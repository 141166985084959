import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormErrors } from 'asap-team/asap-tools';

import { MortgageCheckupItem, MortgageCheckupsCollectionItem, NewRateResponse } from '@core/types';

// Consts
import { ROUTE, MORTGAGE_CHECKUP, newInterestRateErrors } from '@consts/consts';

// Services
import { MortgageCheckupsService } from '@core/services/collections/mortgage-checkup/mortgage-checkup.service';
import { WINDOW } from '@ng-web-apis/common';
import { IqSidebarService } from '@commons/iq-sidebar/iq-sidebar.service';
import { TypedFormGroup } from '@core/types/form-group-config.type';

@UntilDestroy()
@Component({
  selector: 'change-mortgage-rate-sidebar',
  templateUrl: './change-mortgage-rate.component.html',
  styleUrls: ['./change-mortgage-rate.component.scss'],
})
export class ChangeMortgageRateComponent implements OnInit {

  ROUTE: any = ROUTE;

  item: MortgageCheckupItem;

  action$: Subscription;

  form: FormGroup;

  formErrors: FormErrors = newInterestRateErrors;

  isDownloading: boolean = false;

  constructor(
    @Inject(WINDOW) private window: any,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private iqSidebarService: IqSidebarService,
    private mortgageCheckupsService: MortgageCheckupsService,
  ) {
  }

  ngOnInit(): void {
    this.form = this.fb.group<TypedFormGroup<MortgageCheckupsCollectionItem>>(
      { new_interest_rate: [this.item.new_interest_rate, [Validators.required, Validators.min(0.01), Validators.max(10)]] },
    );
  }

  submit(): void {
    const { id, loan_term } = this.item;
    const { new_interest_rate } = this.form.value;

    this.action$ = this
      .mortgageCheckupsService
      .newInterestRate(id, { loan_term, new_interest_rate })
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((response: NewRateResponse) => {
        this.toastr.success(MORTGAGE_CHECKUP.NEW_RATE);
        this.closeSidebar({
          item: {
            id,
            loan_term,
            monthly_savings: response.monthly_savings,
            new_interest_rate: response.new_interest_rate,
            current_interest_rate: response.current_interest_rate,
            apr: response.apr,
          },
        });
      });
  }

  downloadFile(leadId: string, loan_term: number): void {
    const uid: string = leadId.split('.')[0];

    this.isDownloading = true;
    this
      .mortgageCheckupsService
      .download(uid, loan_term)
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(
        ({ file, name }: { file: Blob; name: string }) => {
          this.toastr.success(MORTGAGE_CHECKUP.PDF_GENERATED);
          this.window.saveAs(file, name);
          this.isDownloading = false;
        },
      );
  }

  sendQuote(): void {
    this.closeSidebar({ item: this.item, action: 'send_quote' });
  }

  download(): void {
    this.downloadFile(this.item.id, this.item.loan_term);
  }

  closeSidebar(closeResult?: any): void {
    this.iqSidebarService.close(closeResult);
  }

}
