import { Route } from '@angular/router';

// Consts
import { ROUTE, USER_ROLE } from '@consts/consts';

// Guards
// import { PermissionGuard } from 'asap-team/asap-tools';
import { AuthGuard } from '@core/guards/auth/auth.guard';
import { ProfileGuard } from '@core/guards/profile/profile.guard';
import { RoleGuard } from '@core/guards/role/role.guard';
import { RegistrationCompletedGuard } from '@core/guards/registration-completed/registration-completed.guard';

export const MARKETING: Route = {
  path: ROUTE.name.MARKETING,
  canActivate: [AuthGuard, ProfileGuard, RoleGuard, RegistrationCompletedGuard],
  canLoad: [AuthGuard, ProfileGuard, RoleGuard, RegistrationCompletedGuard],
  loadChildren: () => import('@modules/user/marketing/marketing.module').then((m: any) => m.MarketingModule),
  data: { canBeActivatedBy: [USER_ROLE.agent, USER_ROLE.lender] },
};
