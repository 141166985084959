import {
  AfterViewInit, Component, OnInit, ViewChild, ViewContainerRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { delay, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { Dictionary } from 'asap-team/asap-tools';

import type { Alert, CollectionCounters, NavItem } from '@core/types';

// Consts
import { BILLING_PLAN, PERMITTED_ACTION, ROUTE } from '@consts/consts';

// Services
import { NavigationBuilderService } from '@core/helpers/navigation-builder/navigation-builder.service';
import { AlertsService } from '@core/services/alerts/alerts.service';
import { FirebaseService } from '@core/vendors/firebase/firebase.service';
import { UserService } from '@core/services/user/user.service';
import { MenuService } from '@core/services/menu/menu.service';

@UntilDestroy()
@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit, AfterViewInit {

  alerts$: Observable<Alert[]> = this.alertsService.alerts$.pipe(delay(0));

  profileMenuItems: NavItem[] = [];

  ROUTE: any = ROUTE;

  PERMITTED_ACTION: Dictionary = PERMITTED_ACTION;

  @ViewChild('menuViewContainer', { read: ViewContainerRef, static: true }) menuContainer: ViewContainerRef;

  constructor(
    public menuService: MenuService,
    private router: Router,
    private alertsService: AlertsService,
    private navigationBuilderService: NavigationBuilderService,
    private firebaseService: FirebaseService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.subscribes();
  }

  ngAfterViewInit(): void {
    this.menuService.attachContainer(this.menuContainer);
  }

  get isTrialPlan(): boolean {
    return this.userService.getUserBillingPlan() === BILLING_PLAN.TRIAL;
  }

  logoRedirect(): void {
    this.router.navigate([this.userService.getLogoRoute()]);
  }

  openMenu(): void {
    this.menuService.openMobileMenu();
  }

  private subscribes(): void {
    this
      .navigationBuilderService
      .buildToolbarNavigation()
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((navigation: NavItem[]) => this.menuService.attachNavigation(navigation));

    this
      .firebaseService
      .getCounters()
      .pipe(
        map((counters: CollectionCounters) => !!counters?.total_partnerships_incoming_counter),
        untilDestroyed(this),
      )
      .subscribe((invite: boolean) => {
        this.alertsService.publish({ invite });
      });

    this.navigationBuilderService
      .buildTabsNavigation()
      .subscribe((navigation: NavItem[]) => {
        this.profileMenuItems = [...navigation || []];
      });
  }

}
