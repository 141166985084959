import type { Dictionary } from 'asap-team/asap-tools';

export const COLLECTION_PLACEHOLDER_COPY: any = {
  not_subscribed: {
    title: 'You are not subscribed to&nbsp;𝘮𝘺homeIQ report',
    subtitle: 'Watch video below to learn how <i>my</i>homeIQ can help you grow<br>your repeat and referral business',
  },
  collection_empty: {
    title: 'Welcome to 𝘮𝘺homeIQ',
    subtitle: 'To get started, watch the quick video below and see how you can<br>create your first <i>my</i>homeIQ report.',
  },
};

export const TRAFFIC_COLLECTION_PLACEHOLDER_COPY: any = {
  agent: {
    title: 'Invite your lender and get access to high converting seller funnels',
    subtitle: 'There is no cost to you for unlocking your 𝘮𝘺homeIQ account, simply invite the lender you work and gain access to all the premium features 𝘮𝘺homeIQ has to offer.',
    button: 'Invite your lender',
    outlineButton: 'Get invitation link',
  },
  lender: {
    title: 'Capture motivated buyer leads with automated pre-qualifications',
    subtitle: '𝘮𝘺homeIQ offers a high converting landing page funnel that allows online buyers to pre-qualify for over 16 different loan programs. Click the video below to see how it works.',
    button: 'Customize landing page',
    outlineButton: 'View your landing page',
  },
};

export const DT_EMPTY_STATE_TEMPLATE: Dictionary = {
  emptyMessage: `
    <div class="empty-data-table">
      <img src="assets/icons/data-table/empty_img.svg">
      <h2 class="empty-data-table__title">No results found. <br/> Please try a different search query.</h2>
    </div>
  `,
};

export const INTELLIGENCE_REALTORS_EMPTY_STATE_TEMPLATE: Dictionary = {
  emptyMessage: `
    <div class="empty-data-table">
      <img src="assets/icons/data-table/empty_agent_table_img_v2.svg">
      <h2 class="empty-data-table__title">Can’t find any agent.</h2>
    </div>
  `,
};

export const BANNERS_PAYWALL_LENDER: Dictionary = {
  title: 'Get access to marketing banners <br/> to capture online leads',
  subtitle: 'Renew your subscription and get access to marketing banners',
  button: 'Renew subscription',
};

export const BUYERS_BANNERS_AGENT_LOCK_BY_LENDER: any = {
  title: 'Get access to marketing banners <br/> to capture online leads',
  subtitle: 'This feature currently unavailable on your loan officer’s subscription.<br/>If you want to start or continue using this feature, ask your loan officer<br/>to upgrade their subscription',
};
