import { ToastrService } from 'ngx-toastr';
import { Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ResponseErrorHandler, FormErrors } from 'asap-team/asap-tools';

import type { APIErrorResponse, updatePassword } from '@core/types';

// Consts
import { ROUTE, PASSWORD, confirmPasswordErrors } from '@consts/consts';

// Services
import { UserService } from '@core/services/user/user.service';
import { TypedFormGroup } from '@core/types/form-group-config.type';

@UntilDestroy()
@Component({
  templateUrl: './confirm-pass.component.html',
  styleUrls: ['./confirm-pass.component.scss'],
})
export class ConfirmPassComponent implements OnInit {

  action: Subscription;

  ROUTE: any = ROUTE;

  confirmPasswordForm: FormGroup;

  formErrors: FormErrors = confirmPasswordErrors;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private builder: FormBuilder,
    private toastr: ToastrService,
    private userService: UserService,
    private responseErrorHandler: ResponseErrorHandler,
  ) {}

  ngOnInit(): void {
    this.confirmPasswordForm = this.builder.group<TypedFormGroup<updatePassword>>({
      password: ['', [Validators.required, Validators.minLength(6)]],
      password_confirmation: ['', [Validators.required, Validators.minLength(6)]],
      reset_password_token: [''],
    });
    this
      .confirmPasswordForm
      .controls
      .reset_password_token
      .patchValue(this.route.snapshot.queryParams.reset_password_token);
    this.subscribes();
  }

  subscribes(): void {
    this
      .confirmPasswordForm
      .valueChanges
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.formErrors.global = [];
      });
  }

  confirm(form: FormGroup): void {
    if (form.status === 'INVALID') { return; }

    this.action = this
      .userService
      .updatePassword(form.value)
      .pipe(
        untilDestroyed(this),
        catchError((error: APIErrorResponse) => {
          this.responseErrorHandler.process(error, form, this.formErrors);

          return throwError(error);
        }),
      )
      .subscribe(
        () => {
          this.toastr.success(PASSWORD.CHANGE);
          this.router.navigate([ROUTE.alias.SIGN_IN]);
        },
      );
  }

}
