import {
  Component, Input, OnChanges, Optional, SimpleChanges,
} from '@angular/core';
import { Observable, of } from 'rxjs';
import { IqCardData } from '@commons/components/iq-card/models/iq-card-data.model';

@Component({
  selector: 'iq-card',
  templateUrl: './iq-card.component.html',
  styleUrls: ['./iq-card.component.scss'],
})
export class IqCardComponent implements OnChanges {

  @Optional() @Input() title: string;

  @Optional() @Input() subtitle: string;

  public headerData$: Observable<IqCardData>;

  public ngOnChanges({ title, subtitle }: SimpleChanges): void {
    this.headerData$ = of(this.fetchData(title?.currentValue, subtitle?.currentValue));
  }

  public fetchData(title: string, subtitle: string): IqCardData {
    return {
      doShowHeader: !!(title || subtitle),
      doShowDescription: !!(title || subtitle),
      title: title || '',
      subtitle: subtitle || '',
    };
  }

}
