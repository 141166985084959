/// <reference types="@types/google.maps" />
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { GoogleAPILoaderConfig, US_STATES } from '@consts/consts';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'iq-google-places-filter',
  templateUrl: './iq-google-places-filter.component.html',
  styleUrls: ['./iq-google-places-filter.component.scss'],
})
export class IqGooglePlacesFilterComponent implements OnChanges, OnInit {

  @Input() filterName: string;

  @Input() stateValue: string;

  @Input() placeholder: string = '';

  @Output() emitFilterValue: EventEmitter<{ filterName: string; value: any }> = new EventEmitter();

  @ViewChild('inputRef') inputRef: ElementRef;

  private mapsAPILoader: Loader = new Loader(GoogleAPILoaderConfig);

  placesControl: FormControl<string> = new FormControl();

  predictions: google.maps.places.AutocompletePrediction[] = [];

  isShowPredictions: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    const { stateValue } = changes;

    if (stateValue) {
      const { previousValue, currentValue } = stateValue;

      if (currentValue !== previousValue) {
        this.placesControl.setValue(stateValue?.currentValue || '', { emitEvent: false });
      }
    }
  }

  ngOnInit(): void {
    this.init();
  }

  async init(): Promise<void> {
    try {
      await this.mapsAPILoader.importLibrary('places');

      const autocompleteService: google.maps.places.AutocompleteService = new google.maps.places.AutocompleteService();

      this.placesControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value: string) => {
        autocompleteService.getPlacePredictions(
          {
            input: value,
            componentRestrictions: { country: 'us' },
            types: [
              // City
              'locality',
              'administrative_area_level_3',
              // State
              'administrative_area_level_1',
              // Zip
              'postal_code',
            ],
          },
          (predictions: google.maps.places.AutocompletePrediction[]) => {
            this.predictions = predictions;
          });
      });
    } catch (error) {
      console.error(error);
    }
  }

  selectSuggestion(prediction: google.maps.places.AutocompletePrediction): void {
    const isPredictionState: boolean = prediction.types?.includes('administrative_area_level_1');
    let controlValue: string;

    if (!isPredictionState) {
      controlValue = prediction.structured_formatting.main_text;
    } else {
      const state: { name: string; abbreviation: string } = US_STATES.find((item: {
        name: string;
        abbreviation: string;
      }) => item.name === prediction.structured_formatting.main_text);

      controlValue = state?.abbreviation || prediction.structured_formatting.main_text;
    }

    this.emitFilterValue.emit({ filterName: this.filterName, value: controlValue });
    this.predictions = [];
  }

  onFocus(event?: boolean): void {
    if (event) {
      this.isShowPredictions = true;
    } else {
      setTimeout(() => {
        this.isShowPredictions = false;
      }, 500);
    }
  }

  clear(): void {
    this.emitFilterValue.emit({ filterName: this.filterName, value: null });
    this.placesControl.setValue(null, { emitEvent: false });
  }

}
