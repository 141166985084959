<div class="flex ilist__title">
  <i class="inline-svg--40 m-r-1x" [inlineSVG]="'#icon-preapprovemeapp-logo'"></i>
  <h1 class="f-34-600">Pre-Approve Me</h1>
  <div class="ilist__status ilist__connected-status f-14-normal m-l-2x" *ngIf="connected">connected</div>
  <div class="ilist__status f-14-normal m-l-2x" *ngIf="isReadyToConnect">{{ preApproveMe.status_title }}</div>
</div>
<div class="f-14-500 f-c-steel m-b-2x m-t-3x" *ngIf="preApproveMe.enabled">
  Use this key on <a [href]="preApproveMe.website">preapprovemeapp.com</a> to connect <i>my</i>homeIQ account with it.
</div>
<div class="f-14-500 f-c-steel m-b-2x m-t-3x" *ngIf="!preApproveMe.enabled">
  Integration <a [href]="preApproveMe.website">preapprovemeapp.com</a> with <i>my</i>homeIQ account, will allow you
  to automatically receive and send leads between applications.
</div>
<div class="control m-b-2x" *ngIf="preApproveMe.enabled">
  <div class="control__prepend w-100">
    <div class="control__label w-100">
      <div class="flex-center w-100">
        <div class="control__label-text flex-1">
          <div class="text-overflow">{{ preApproveMe?.api_key }}</div>
        </div>
        <div class="m-l-1x m-r-1x">
          <iq-button size="24"
                     color="grey"
                     [style]="'transparent'"
                     iconLeft="#icon-copy"
                     ngxClipboard=""
                     [cbContent]="preApproveMe?.api_key"></iq-button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="ilist__actions">
  <iq-button class="m-t-1x"
             *ngIf="!preApproveMe.enabled"
             size="40"
             color="blue"
             label="Activate"
             (click)="handleActivateIntegration()"></iq-button>
  <iq-button class="block"
             *ngIf="isReadyToConnect || connected"
             color="blue"
             [style]="'outline'"
             label="Generate new key"
             (click)="handleGenerateNewKey()"></iq-button>
</div>
<div class="f-12-400 f-c-steel m-b-2x m-t-3x" *ngIf="connected || isReadyToConnect">
  <span class="m-r-1x" *ngIf="preApproveMe.last_activity_at">
    Last connect: {{preApproveMe.last_activity_at | date: 'dd MMM, yyyy - hh:mm a' }}
  </span>
  <span class="f-12-600 f-c-blue-main pointer" (click)="handleDisableIntegration()">Disconnect</span>
</div>
