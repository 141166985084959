<ng-container *ngIf="headerData$ | async as header">
  <div *ngIf="header.doShowHeader" class="iq-card-header">
    <div *ngIf="header.doShowDescription" class="iq-card-info">
      <div class="iq-card-descriptions">
        <div class="iq-card-title">{{ header.title }}</div>
        <div class="iq-card-subtitle">{{ header.subtitle }}</div>
      </div>
      <div class="iq-card-badges">
        <ng-content select="[slot=badges]"></ng-content>
      </div>
    </div>
    <div class="iq-card-actions">
      <ng-content select="[slot=actions]"></ng-content>
    </div>
  </div>
</ng-container>

<div class="iq-card-body">
  <ng-content></ng-content>
</div>
