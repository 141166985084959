import { ActivatedRoute } from '@angular/router';
import {
  Component,
  OnInit,
  ViewContainerRef,
  ViewChild,
} from '@angular/core';

import type { Dictionary } from 'asap-team/asap-tools';
import type { BlockedType } from '@core/types';

// Consts
import { BLOCKED_TYPE } from '@consts/consts';

@Component({
  templateUrl: './restricted-access.component.html',
  styleUrls: ['./restricted-access.component.scss'],
})
export class RestrictedAccessComponent implements OnInit {

  @ViewChild('container', { read: ViewContainerRef, static: true }) private container: ViewContainerRef;

  modules: Dictionary<{ load: () => Promise<any> }> = {
    [BLOCKED_TYPE.MANUAL_BLOCK]: { load: (): any => import('./blocked-by-admin/blocked-by-admin.component').then((c: any) => c.BlockedByAdminComponent) },
    [BLOCKED_TYPE.SUBSCRIPTION_EXPIRED_BLOCK]: { load: (): any => import('./blocked-by-subscription/blocked-by-subscription.component').then((c: any) => c.BlockedBySubscriptionComponent) },
    [BLOCKED_TYPE.SUSPEND]: { load: (): any => import('./blocked-by-subscription/blocked-by-subscription.component').then((c: any) => c.BlockedBySubscriptionComponent) },
  };

  constructor(
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.setState(this.route.snapshot.data?.state?.blocked_type);
  }

  private getComponentModule(name: string): { load: () => Promise<any> } {
    return this.modules[name];
  }

  private async createComponent(name: string): Promise<void> {
    if (!name) { return; }

    this.container.clear();
    this.container.createComponent(await this.getComponentModule(name).load());
  }

  private setState(blocked_type: BlockedType): void {
    if (!blocked_type) { return; }

    this.createComponent(blocked_type);
  }

}
