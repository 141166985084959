<div class="socials__list">
  <a *ngIf="socials?.facebook_url"
      class="socials__list--link"
      [href]="socials?.facebook_url"
      target="_blank">
    <img src="assets/components/profile/socials/facebook.svg" alt="facebook-link">
  </a>
  <a *ngIf="socials?.linkedin_url"
      class="socials__list--link"
      [href]="socials?.linkedin_url"
      target="_blank">
    <img src="assets/components/profile/socials/linkedin.svg" alt="linkedin-link">
  </a>
  <a *ngIf="socials?.instagram_url"
      class="socials__list--link"
      [href]="socials?.instagram_url"
      target="_blank">
    <img src="assets/components/profile/socials/instagram.svg" alt="instagram-link">
  </a>
  <a *ngIf="socials?.youtube_url"
      class="socials__list--link"
      [href]="socials?.youtube_url"
      target="_blank">
    <img src="assets/components/profile/socials/youtube.svg" alt="youtube-link">
  </a>
  <a *ngIf="socials?.zillow_url"
      class="socials__list--link"
      [href]="socials?.zillow_url"
      target="_blank">
    <img src="assets/components/profile/socials/zillow.svg" alt="zillow-link">
  </a>
  <a *ngIf="socials?.realtor_url"
      class="socials__list--link"
      [href]="socials?.realtor_url"
      target="_blank">
    <img src="assets/components/profile/socials/realtor.svg" alt="realtor-link">
  </a>
  <a *ngIf="socials?.personal_website_url"
      class="socials__list--link"
      [href]="socials?.personal_website_url"
      target="_blank">
    <img src="assets/components/profile/socials/website.svg" alt="website-link">
  </a>
</div>
