import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subscription } from 'rxjs';

// Consts
import { profileErrors } from '@consts/form-errors';
import { USER_ROLE } from '@consts/user';

// Types
import { CheckboxItem, UserRole, Profile } from '@core/types';

// Services
import { UserService } from '@core/services/user/user.service';
import { DictionaryService } from '@core/services/dictionary/dictionary.service';
import {
  APIErrorResponse,
  COMMON_TOAST,
  FormErrors,
  ResponseErrorHandler,
  Dictionary,
} from 'asap-team/asap-tools';
import { ToastrService } from 'ngx-toastr';
import { DialogRef } from '@angular/cdk/dialog';
import { TypedFormGroup } from '@core/types/form-group-config.type';

@UntilDestroy()
@Component({
  templateUrl: './complete-profile.component.html',
  styleUrls: ['./complete-profile.component.scss'],
})
export class CompleteProfileComponent implements OnInit {

  form: FormGroup;

  formErrors: FormErrors = profileErrors;

  USER_ROLE: Dictionary<UserRole> = USER_ROLE;

  states$: Observable<CheckboxItem[]>;

  profile: Profile;

  updateProfileAction$: Subscription;

  timeZones$: Observable<CheckboxItem []> = this.dictionaryService.timeZones$;

  mlsTooltipText: string = 'As a member of an MLS, you receive an number known as an MLS ID, Agent ID, or Public ID.. This serves as your primary "username" or "login" credential for accessing the MLS system, typically comprising a combination of letters, numbers, or both.';

  constructor(
    private builder: FormBuilder,
    private userService: UserService,
    private toastr: ToastrService,
    private dialogRef: DialogRef,
    private responseErrorHandler: ResponseErrorHandler,
    private dictionaryService: DictionaryService,
  ) {
  }

  ngOnInit(): void {
    this.states$ = this.userService.getAvailablePropertiesStates();
    this.getProfile();

    this.form = this.builder.group<TypedFormGroup<Profile>>({
      license_number: [this.profile?.license_number, [Validators.required, Validators.minLength(3)]],
      license_states: [this.profile?.license_states, [Validators.required]],
      mls_number: [this.profile?.mls_number, [Validators.required]],
      company_name: [this.profile?.company_name, [Validators.required, Validators.minLength(3)]],
      company_license_number: [
        this.profile?.company_license_number,
        [Validators.required, Validators.minLength(3), Validators.maxLength(50)],
      ],
      time_zone: [this.profile?.time_zone || null, [Validators.required]],
    });
  }

  private getProfile(): void {
    this
      .userService
      .profile$
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((profile: Profile) => {
        this.profile = profile;
      });
  }

  completeProfile(): void {
    this.updateProfileAction$ = this
      .userService
      .updateProfile(this.form.value)
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(
        () => {
          this.toastr.success(COMMON_TOAST.CHANGES_SAVED);
          this.closeModal();
        },
        (error: APIErrorResponse) => {
          this.responseErrorHandler.process(error, this.form, this.formErrors);
        },
      );
  }

  get isAgentRole(): boolean {
    return this.userService.isUserRole(USER_ROLE.agent);
  }

  closeModal(): void {
    this.dialogRef.close();
  }

}
