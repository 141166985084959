<div class="placeholder">
  <ng-container *ngIf="isAgentTrial; else pausedSubState">
    <p class="placeholder__title f-32-bold">Invite Loan Officer and get access to marketing banners</p>
    <p class="placeholder__subtitle f-16-normal">
      There is no cost to you for unlocking your myhomeIQ account, simply invite the lender you work and gain access to all the premium features myhomeIQ has to offer.
    </p>

    <div class="placeholder__buttons">
      <iq-button [label]="'Invite loan officer'"
                 [routerLink]="[ROUTE.alias.PARTNERSHIP]"
                 [queryParams]="{ 'invite_sidebar': true }"></iq-button>
      <iq-button [label]="'Get invitation link'"
                 [style]="'outline'"
                 (click)="showGetLinkModal()"></iq-button>
    </div>
  </ng-container>

  <ng-template #pausedSubState>
    <p class="placeholder__title f-32-bold" style="max-width: 575px">Get access to marketing banners to capture online
      leads</p>
    <p class="placeholder__subtitle f-16-normal">
      Renew your subscription and get access to marketing banners
    </p>

    <div class="placeholder__buttons">
      <ng-container *ngIf="isLenderSubIsPaused; else contactSupportState">
        <iq-button [label]="'Renew subscription'" [routerLink]="ROUTE.alias.BILLING"></iq-button>
      </ng-container>

      <ng-template #contactSupportState>
        <a href="mailto:support@myhomeiq.com">
          <iq-button label="Contact support"></iq-button>
        </a>
      </ng-template>
    </div>
  </ng-template>
</div>
<div class="placeholder__image">
  <img src="assets/components/collections/traffic/seller-banner.png"/>
  <div class="placeholder__image--hint">
    <img src="assets/components/collections/traffic/banner-access.png"/>
  </div>
  <!--button.placeholder__image--button-->
  <!--  img(src="assets/components/collections/traffic/play-button.svg")-->
</div>
