import { NgModule } from '@angular/core';
import { DialogModule } from '@angular/cdk/dialog';
import { IqModalContainerComponent } from '@commons/iq-modal/components/iq-modal-container/iq-modal-container.component';

@NgModule({
  declarations: [
    IqModalContainerComponent,
  ],
  imports: [
    DialogModule,
  ],
  exports: [
    IqModalContainerComponent,
  ],
})
export class IqModalModule {
}
