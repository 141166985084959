<div class="flex lending-pad__title">
  <i class="inline-svg--40 m-r-2x" [inlineSVG]="'#icon-lending_pad-logo'"></i>
  <h1 class="f-34-600">LendingPad</h1>
  <div class="lending-pad__connected-status f-14-normal m-l-2x"
       *ngIf="lendingPad.enabled && lendingPad.lending_pad_company_id">
       connected
  </div>
  <div class="lending-pad__receining-status f-14-normal m-l-2x"
       *ngIf="lendingPad.enabled && !lendingPad.lending_pad_company_id">
    Receiving only
  </div>
</div>
<div class="f-14-500 f-c-steel l-h-22 m-b-2x m-t-3x">
  Connect to LendingPad to receive homeowner data. Simply copy and paste the company and campaign identity code found in
  the Settings/Integrations section of LendingPad CRM.
</div>
<form [formGroup]="form" (submit)="connect()">
  <iq-form-control-mask class="m-b-2x"
                        *ngIf="lendingPad.enabled"
                        label="LP company ID"
                        placeholder="Company Identifier"
                        formControlName="lending_pad_company_id"
                        [mask]="'AAAAAAAA-AAAA-AAAA-AAAA-AAAAAAAAAAAA'"
                        [dropSpecialCharacters]="false"></iq-form-control-mask>
  <iq-form-control-mask class="m-b-2x"
                        *ngIf="lendingPad.enabled"
                        label="LP campaign ID"
                        placeholder="Campaign Identifier"
                        formControlName="lending_pad_campaign_id"
                        [mask]="'AAAAAAAA-AAAA-AAAA-AAAA-AAAAAAAAAAAA'"
                        [dropSpecialCharacters]="false"></iq-form-control-mask>
  <iq-button class="block"
             type="submit"
             [label]="lendingPad.enabled ? 'Save' : 'Connect'"
             [disabled]="lendingPad.enabled && (form.pristine || form.invalid)"
             [busy$]="connectAction$"></iq-button>
</form>
<div class="f-12-400 f-c-steel m-b-2x m-t-3x" *ngIf="lendingPad.enabled">
  <span class="m-r-1x" *ngIf="lendingPad.last_activity_at">
    Last connect: {{lendingPad.last_activity_at | date:'dd MMM, yyyy - hh:mm a' }}
  </span>
  <span class="f-12-600 f-c-blue-main pointer" (click)="disable()">Disconnect</span>
</div>
