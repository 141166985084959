import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { switchMap, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ResponseErrorHandler } from 'asap-team/asap-tools';

import { Observable, Subscription } from 'rxjs';

// Types
import {
  APIErrorResponse,
  Checkout,
  CheckoutInfo,
  Invoice,
  PaymentMethod,
  Plan,
  Profile,
  ProfileResponse,
} from '@core/types';

// Interfaces
import { CheckoutWithPromoCode } from '@core/interfaces';

// Consts
import { COMMON_TOAST, ORDER, PERMITTED_ACTION } from '@consts/consts';

// Components
import { AddCardComponent } from '@commons/modals/add-card/add-card.component';
import { CheckoutSuccessComponent } from '@commons/modals/checkout-success/checkout-success.component';
import { CheckoutSuccessDiscountComponent } from '@commons/modals/checkout-success-discount/checkout-success-discount.component';
import { CheckoutErrorComponent } from '@commons/modals/checkout-error/checkout-error.component';

// Services
import { BillingService } from '@core/services/billing/billing.service';
import { UserService } from '@core/services/user/user.service';
import { IqModalService } from '@commons/iq-modal/iq-modal.service';

@UntilDestroy()
@Component({
  selector: 'order-checkout',
  templateUrl: './order-checkout.component.html',
  styleUrls: ['./order-checkout.component.scss'],
})
export class OrderCheckoutComponent implements OnInit {

  @Input() invoices: Invoice[] = [];

  @Input() plan: Plan;

  @Output() purchaseOrder: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() checkoutEvent: EventEmitter<Checkout> = new EventEmitter<Checkout>();

  @Output() checkoutInfoEvent: EventEmitter<CheckoutInfo> = new EventEmitter<CheckoutInfo>();

  action: Subscription;

  card: PaymentMethod;

  checkout: Checkout = null;

  checkoutInfo: CheckoutInfo = null;

  promoCode: string | null = null;

  constructor(
    private userService: UserService,
    private billingService: BillingService,
    private iqModalService: IqModalService,
    private toastr: ToastrService,
    private responseErrorHandler: ResponseErrorHandler,
  ) {}

  ngOnInit(): void {
    this.subscribes();
  }

  private subscribes(): void {

    // get checkout for subscription upgrade
    if (this.plan?.name) {
      this.billingService.checkout(this.plan.name).subscribe((response: Checkout) => {
        this.checkout = response;
        this.checkoutEvent.emit(response);
      });

      // get checkout for simple invoice
    } else if (!this.plan) {
      this.billingService.getCheckout().subscribe((response: CheckoutInfo) => {
        this.checkoutInfo = response;
        this.checkoutInfoEvent.emit(response);
      });
    }

  }

  private checkoutSuccessModal(): void {
    let checkoutSuccess$: Observable<void>;
    const { permitted_actions }: Profile = this.userService.syncGetProfile();

    const hasReferralProgram: boolean = permitted_actions.includes(PERMITTED_ACTION.REFERRAL_MANAGE);

    if (this.plan && hasReferralProgram) {
      checkoutSuccess$ = this.iqModalService
        .open(CheckoutSuccessDiscountComponent, { discountCountdownDate: new Date().toISOString() })
        .closed;
    } else {
      checkoutSuccess$ = this.iqModalService.open(CheckoutSuccessComponent).closed;
    }

    checkoutSuccess$
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.purchaseOrder.emit(true);
        this.billingService.paymentMethodsChange();
      });
  }

  private checkoutErrorModal(error: any): void {
    this.iqModalService.open(CheckoutErrorComponent, { error });
  }

  private purchasePlanUpgrade(): void {
    this.action = this.billingService.reservationsUpgrade({
      plan_name: this.plan.name,
      token: this.card.token,
      ...(this.promoCode && { promo_code: this.promoCode }),
    }).pipe(
      untilDestroyed(this),
    ).subscribe(
      (response: { data: Profile; hash: string }) => {
        this.userService.updateProfile(response.data, false);
        this.toastr.success(ORDER.COMPLETE);
        this.checkoutSuccessModal();
      },
      (error: APIErrorResponse) => this.handleCheckoutError(error),
    );
  }

  private purchaseInvoice(): void {
    this.action = this.billingService.purchase({
      invoice_ids: this.invoiceIds,
      token: this.card.token,
    }).pipe(
      tap(({ data }: ProfileResponse) => this.userService.updateProfile(data, false)),
      switchMap(() => this.billingService.getSubscription()),
      untilDestroyed(this),
    ).subscribe(
      () => {
        this.toastr.success(ORDER.COMPLETE);
        this.checkoutSuccessModal();
      },
      (error: APIErrorResponse) => this.handleCheckoutError(error),
    );
  }

  private handleCheckoutError(error: APIErrorResponse): void {
    if (error?.error) {
      this.checkoutErrorModal(this.responseErrorHandler.format(error.error.errors));

      return;
    }

    this.checkoutErrorModal(COMMON_TOAST.SOMETHING_WENT_WRONG);
  }

  get invoiceIds(): string {
    return this.invoices?.map((item: Invoice) => item.id).join(',');
  }

  addCardModal(event: boolean): void {
    if (event) {
      this.iqModalService.open(AddCardComponent, { primaryByDefault: true });
    }
  }

  selectCard(card: PaymentMethod): void {
    this.card = card;
  }

  updateCheckout({ checkout, promoCode }: CheckoutWithPromoCode): void {
    if (this.plan) {
      this.checkout = checkout;
    } else {
      this.checkoutInfo = checkout as CheckoutInfo;
    }

    this.promoCode = promoCode || null;
  }

  submit(): void {
    if (!this.card) {
      return;
    }

    if (this.plan) {
      this.purchasePlanUpgrade();

      return;
    }

    this.purchaseInvoice();
  }

}
