import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE } from '@consts/routes';
import { AsaplyticsService } from '@core/helpers/tracking/asaplytics.service';
import { DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'checkout-success-discount',
  templateUrl: './checkout-success-discount.component.html',
})
export class CheckoutSuccessDiscountComponent {

  @Input() discountCountdownDate: string;

  ROUTE: any = ROUTE;

  constructor(
    private router: Router,
    private asaplyticsService: AsaplyticsService,
    private dialogRef: DialogRef,
  ) {
  }

  redirectToReferralDiscounts(): void {
    this.router.navigate([ROUTE.alias.REFERRAL_MANAGE]);
    this.asaplyticsService.sendEvent({ category: 'Referral', action: 'refer_click' });
    this.closeModal();
  }

  skipReferralDiscount(): void {
    this.asaplyticsService.sendEvent({ category: 'Referral', action: 'skip_referral_click' });
    this.closeModal();
  }

  closeModal(): void {
    this.dialogRef.close();
  }

}
