<div class="illustration illustration-1" *ngIf="isCurrentTemplate(TEMPLATE.ILLUSTRATION_1)">
  <div class="image"><img src="assets/components/ftl/sidebar/illustration-1.svg"/></div>
  <div class="description m-t-1x" *roleIf="USER_ROLE.agent">
    According to NAR,
    <b>70% of realtors lose their clients</b>
    to other Agents.
  </div>
  <div class="description m-t-1x" *roleIf="USER_ROLE.lender">According to NAR,<br/><b>most lenders lose their
    clients</b><br/>to other Loan Officers.
  </div>
</div>
<div class="illustration illustration-2" *ngIf="isCurrentTemplate(TEMPLATE.ILLUSTRATION_2)">
  <div class="image"><img src="assets/components/ftl/sidebar/illustration-2.svg"/></div>
  <div class="description m-t-4x">
    You can’t change the past, but you can
    <b>make sure</b>
    this does
    <b>not happen again</b>
    using our predictive analytics tool.
  </div>
</div>
<div class="illustration illustration-3" *ngIf="isCurrentTemplate(TEMPLATE.ILLUSTRATION_3)">
  <div class="image"><img src="assets/components/ftl/sidebar/illustration-3.svg"/></div>
  <ng-container *roleIf="USER_ROLE.agent">
    <div class="description m-t-4x">
      “I got a
      <b>new listing the first month</b>
      of signing up for <i>my</i>homeIQ,
      I love that most of the heavy lifting is done for me.”
    </div>
    <div class="flex m-t-3x"><img src="assets/components/ftl/sidebar/dm.png"/>
      <div class="align-self-center p-l-2x">
        <div class="f-16-600 f-c-grey-main">Tim P.</div>
        <div class="f-14-600 f-c-grey-blue">Coldwell Banker</div>
      </div>
    </div>
  </ng-container>
  <ng-container *roleIf="USER_ROLE.lender">
    <div class="description m-t-4x">
      My clients are already
      <b>bringing me deals</b>
      now that I am giving them useful information to help them make better financial decisions.
      <div class="flex m-t-3x"><img src="assets/components/ftl/sidebar/mk.png"/>
        <div class="align-self-center p-l-2x">
          <div class="f-16-600 f-c-grey-main">Mike K.</div>
          <div class="f-14-600 f-c-grey-blue">Answer Home Loans</div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<div class="illustration illustration-5" *ngIf="isCurrentTemplate(TEMPLATE.ILLUSTRATION_5)">
  <div class="image"><img class="report" src="assets/components/ftl/sidebar/illustration-5.svg"/></div>
  <div class="description m-t-4x">
    Did you know that
    <b>90% of wealth at retirement</b>
    for most people comes from their home?
  </div>
</div>
<div class="illustration illustration-6" *ngIf="isCurrentTemplate(TEMPLATE.ILLUSTRATION_6)">
  <div class="image"><img class="report" src="assets/components/ftl/sidebar/illustration-6.svg"/></div>
  <div class="description m-t-4x"><i>my</i>homeIQ
    <b>increases client engagement by 24x</b>
    with an open rate of 84%
  </div>
</div>
<div class="illustration illustration-7" *ngIf="isCurrentTemplate(TEMPLATE.ILLUSTRATION_7)">
  <div class="image">
    <div class="report"></div>
  </div>
</div>
<div class="illustration illustration-8" *ngIf="isCurrentTemplate(TEMPLATE.ILLUSTRATION_8)">
  <div class="image"><img src="assets/components/ftl/sidebar/illustration-8.svg"/></div>
  <div class="description m-t-4x">Agents with lenders have a<br/><b>3x higher engagement</b><br/>from homeowners.</div>
</div>
<div class="illustration illustration-9" *ngIf="isCurrentTemplate(TEMPLATE.ILLUSTRATION_9)">
  <div class="image"><img src="assets/components/ftl/sidebar/illustration-9.svg"/></div>
  <div class="description m-t-4x">
    Knowing your CRM will help us improve your experience with <i>my</i>homeIQ.
  </div>
</div>
<div class="illustration illustration-9" *ngIf="isCurrentTemplate(TEMPLATE.ILLUSTRATION_2_0)">
  <div class="image"><img src="assets/components/ftl/sidebar/illustration-2_0.png"/></div>
</div>
<div class="illustration illustration-9" *ngIf="isCurrentTemplate(TEMPLATE.ILLUSTRATION_2_1)">
  <div class="image"><img src="assets/components/ftl/sidebar/illustration-2_1.png"/></div>
</div>
<div class="illustration illustration-9" *ngIf="isCurrentTemplate(TEMPLATE.ILLUSTRATION_2_2)">
  <div class="image"><img src="assets/components/ftl/sidebar/illustration-2_2.png"/></div>
</div>
<div class="illustration illustration-9" *ngIf="isCurrentTemplate(TEMPLATE.ILLUSTRATION_2_3)">
  <div class="image"><img src="assets/components/ftl/sidebar/illustration-2_3.png"/></div>
</div>
<div class="illustration illustration-9" *ngIf="isCurrentTemplate(TEMPLATE.ILLUSTRATION_2_4)">
  <div class="image"><img src="assets/components/ftl/sidebar/illustration-2_4.png"/></div>
</div>
<div class="illustration illustration-9" *ngIf="isCurrentTemplate(TEMPLATE.ILLUSTRATION_2_5)">
  <div class="image"><img src="assets/components/ftl/sidebar/illustration-2_5.png"/></div>
</div>
<div class="illustration illustration-9" *ngIf="isCurrentTemplate(TEMPLATE.ILLUSTRATION_2_6)">
  <div class="image"><img src="assets/components/ftl/sidebar/illustration-2_6.png"/></div>
</div>
