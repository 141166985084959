import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Component, Input, OnInit } from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';

import {
  Dictionary,
  CollectionResponse,
  isPermitted,
} from 'asap-team/asap-tools';

// Consts
import { environment } from 'environments/environment';
import {
  ROUTE,
  USER_ROLE,
  BILLING_PLAN,
  PERMITTED_ACTION,
} from '@consts/consts';
import type { Profile } from '@core/types/Profile';

// Services
import { UserService } from '@core/services/user/user.service';
import { ActivePartnersService } from '@core/services/collections/partnership/active-partners.service';

// Components
import { YoutubeVideoComponent } from '@commons/modals/youtube-video/youtube-video.component';
import { GetInviteLinkComponent } from '@commons/modals/get-invite-link/get-invite-link.component';
import { ActivePartner } from '@core/types';
import { isEqual } from 'lodash-es';
import { IqModalService } from '@commons/iq-modal/iq-modal.service';

@UntilDestroy()
@Component({
  selector: 'buyer-collection-placeholder',
  templateUrl: 'buyer-collection-placeholder.component.html',
  styleUrls: ['buyer-collection-placeholder.component.scss'],
})
export class BuyerCollectionPlaceholderComponent implements OnInit {

  @Input() isLenderEmptyState: boolean = false;

  copy: Dictionary;

  profile: Profile;

  landingUrl: string;

  PERMITTED_ACTION: any = PERMITTED_ACTION;

  BILLING_PLAN: any = BILLING_PLAN;

  USER_ROLE: any = USER_ROLE;

  ROUTE: any = ROUTE;

  constructor(
    private userService: UserService,
    private iqModalService: IqModalService,
    private activePartnersService: ActivePartnersService,
  ) { }

  ngOnInit(): void {
    this.userService.profile$
      .pipe(
        distinctUntilChanged(isEqual),
        untilDestroyed(this),
      ).subscribe((profile: Profile) => {
        this.profile = profile;
        this.setLandingUrl();
      });
  }

  get isSubPaused(): boolean {
    return isPermitted(
      this.profile?.permitted_actions,
      [PERMITTED_ACTION.BUYER_LEADS_LOCK_SUBSCRIPTION_RENEW, PERMITTED_ACTION.BUYER_LEADS_LOCK_BY_LENDER_UPGRADE],
    );
  }

  get isAgentTrial(): boolean {
    return this.profile?.plan_name === BILLING_PLAN.TRIAL || this.profile?.plan_name === BILLING_PLAN.SOLO_PLAN;
  }

  get isConnectedAgent(): boolean {
    return this.profile?.plan_name === BILLING_PLAN.CONNECTED_PLAN;
  }

  get isPremiumAgent(): boolean {
    return this.profile?.apps?.realtor_premium?.enabled;
  }

  get isContactSupportState(): boolean {
    // eslint-disable-next-line max-len
    return !this.profile?.apps?.buyer_traffic?.enabled && isPermitted(this.profile?.permitted_actions, PERMITTED_ACTION.BUYER_LEADS_STATE_PROMO);
  }

  setLandingUrl(): void {
    const { role, plan_name } = this.profile;

    if (role === USER_ROLE.agent && !(plan_name === BILLING_PLAN.TRIAL || plan_name === BILLING_PLAN.SOLO_PLAN) && !this.isSubPaused) {
      this.activePartnersService.getCollection(null).subscribe((response: CollectionResponse<ActivePartner>) => {
        const partner: ActivePartner = response.data.find((item: ActivePartner) => item.primary);

        this.landingUrl = `${environment.buyerIQ}/${partner ? `${partner.username}?partner=${this.profile.username}` : this.profile.username}`;
      });
    }
  }

  showGetLinkModal(): void {
    this.iqModalService.open(GetInviteLinkComponent);
  }

  showVideoModal(): void {
    const id: any = 'https://www.youtube.com/embed/0AsIW77E3Dc';

    this.iqModalService.open(YoutubeVideoComponent, { id });
  }

}
