import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';

// Components
import { CountdownModalComponent } from '@commons/modals/countdown-modal/countdown-modal.component';
import { switchMap } from 'rxjs/operators';
import { UserService } from '@core/services/user/user.service';
import { FTL_STEP } from '@consts/user';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

// Consts
import { ANALYTICS_EVENTS } from '@consts/analytics';

// Services
import { AsaplyticsService } from '@core/helpers/tracking/asaplytics.service';
import { IqModalService } from '@commons/iq-modal/iq-modal.service';

@UntilDestroy()
@Component({
  selector: 'ftl-view-simple-report',
  templateUrl: './ftl-view-simple-report.component.html',
  styleUrls: ['./ftl-view-simple-report.component.scss'],
})
export class FtlViewSimpleReportComponent implements OnInit {

  generationSteps: string[] = [
    'Estimated Home Value',
    'Estimated loan balance',
    'Selling Fees',
    'Title & Escrow Fees',
    'Fees',
    'Refinance Opportunity',
    'Mortgage Insurance',
    'Amortization Chart',
    'Rental Value',
  ];

  constructor(
    private iqModalService: IqModalService,
    private userService: UserService,
    private asaplyticsService: AsaplyticsService,
  ) {}

  ngOnInit(): void {
    this.openCountdownModal()
      .pipe(
        switchMap(() => this.userService.submitFtlStep(FTL_STEP.view_sample_report)),

        untilDestroyed(this),
      )
      .subscribe(
        () => this.asaplyticsService.sendEvent(
          ANALYTICS_EVENTS.FTL.agent.manual.sms_popup_step,
        ),
        (error: Error) => console.log(error),
        () => this.asaplyticsService.sendEvent(
          ANALYTICS_EVENTS.FTL.agent.manual.sms_popup_ended,
        ),
      );
    this.asaplyticsService.sendEvent(
      ANALYTICS_EVENTS.FTL.agent.manual.generating_report_step,
    );
  }

  private openCountdownModal(): Observable<any> {
    return this.iqModalService.open(CountdownModalComponent, null, { disableClose: true }).closed;
  }

}
