<div class="iq-sidebar">
  <div class="modal-header">
    <div class="modal-header__title" *roleIf="USER_ROLE.agent">Invite {{ USER_LABEL.loan_officer_title }}</div>
    <div class="modal-header__title" *roleIf="USER_ROLE.lender">Invite {{ USER_LABEL.agent_title }}</div>
    <div class="modal-close inline-svg" [inlineSVG]="'#icon-close'" (click)="closeSidebar()"></div>
  </div>
  <div class="modal-body">
    <ng-container *ngIf="inviteState">
      <invite-partner-form [query]="query"
                           [userRole]="userRole">
      </invite-partner-form>
      <iq-button class="block"
                 label="Send invitation"
                 [disabled]="invitePartnerForm?.form?.pristine || invitePartnerForm?.form?.invalid"
                 [busy$]="submitAction"
                 (onClick)="invite()"></iq-button>
      <iq-button class="block m-t-2x" label="Cancel" color="grey" [style]="'outline'"
                 (onClick)="closeSidebar(); sendGAEvent('cancel_email_invite')"></iq-button>
      <p class="invite-partner__help-text" *roleIf="USER_ROLE.agent">
        The lender will receive an email and sms introducing them to <i>my</i>homeIQ.
        You are acknowledging that you have a working relationship with this lender.
      </p>
      <p class="invite-partner__help-text" *roleIf="USER_ROLE.lender">
        The agent will receive an email and sms introducing them to <i>my</i>homeIQ.
        You are acknowledging that you have a working relationship with this agent.
      </p>
      <div class="text-center">
        <div class="invite-process-btn m-b-24" (click)="showInviteProcessModal()">
          <i class="inline-svg" [inlineSVG]="'#icon-eye'"></i>
          See what happens when you send the invite
        </div>
      </div>
    </ng-container>
    <div class="invite-partner__success" *ngIf="!inviteState">
      <img class="invite-partner__success-icon" src="assets/images/intelligence/success-icon.svg"/>
      <h2>Invitation Sent</h2>
      <p>We have seen a <b>3x higher invitation acceptance</b> when you follow up with a quick phone call.</p>
      <div class="invite-partner__success-info">
        <div class="m-b-8 f-16-bold f-c-gray-main">{{ invitePartnerForm?.form?.value?.name }}</div>
        <div class="f-16-normal f-c-gray-main">{{ invitePartnerForm?.form?.value?.phone | phone }}</div>
        <div class="f-16-normal f-c-gray-main">{{ invitePartnerForm?.form?.value?.email }}</div>
      </div>
      <iq-button class="block m-t-4x" label="Got it" (onClick)="closeSidebar()"></iq-button>
    </div>
  </div>
</div>
