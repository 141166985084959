import { Component, OnInit } from '@angular/core';
import { VaultService } from 'asap-team/asap-tools';

// Consts
import { ARCHIVE_LEAD_SESSION } from '@consts/consts';
import { DialogRef } from '@angular/cdk/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TypedFormGroup } from '@core/types/form-group-config.type';

@Component({ templateUrl: './archive-confirm.component.html' })
export class ArchiveConfirmComponent implements OnInit {

  mode: string;

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private dialogRef: DialogRef,
    private vaultService: VaultService,
  ) {
  }

  ngOnInit(): void {
    this.form = this.fb.group<TypedFormGroup<{ show: boolean }>>({ show: [false] });
  }

  confirm(): void {
    if (this.form.controls.show.value) {
      this.vaultService.set(ARCHIVE_LEAD_SESSION, true, 'session');
    }

    this.closeModal(!this.form.controls.show.value);
  }

  closeModal(closeResult?: boolean): void {
    this.dialogRef.close(closeResult);
  }

}
