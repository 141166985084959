import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  EventEmitter,
  Component,
  OnInit,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Dictionary, FormErrors } from 'asap-team/asap-tools';
import { addressValidator } from '@core/validators/address/address.validator';

// Consts
import { googleAddressErrors } from '@consts/form-errors';
import { IqFormControlAddressComponent } from '@commons/forms/form-controls/iq-form-control-address/iq-form-control-address.component';
import { TypedFormGroup } from '@core/types/form-group-config.type';
import { Address } from '@core/types';

@UntilDestroy()
@Component({
  selector: 'google-address-form',
  templateUrl: './google-address-form.component.html',
})
export class GoogleAddressFormComponent implements OnInit {

  @Input() initialState: Address = null;

  @Input() placeholder: string;

  @Output() formComplete: EventEmitter<{ address: Dictionary | null }> = new EventEmitter<{ address: Dictionary | null }>();

  @ViewChild(IqFormControlAddressComponent) addressComponent: IqFormControlAddressComponent;

  form: FormGroup;

  formErrors: FormErrors = googleAddressErrors;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initForm();
    this.setInitialState();
    this.subscribeToFormChange();
  }

  private initForm(): void {
    this.form = this.fb.group<TypedFormGroup<{ address: Address }>>({
      address: [
        {
          full_address: '',
          unit: '',
          city: '',
          state: '',
          street: '',
          zip: '',
          country: '',
          lat: null,
          lng: null,
        },
        [Validators.required, addressValidator],
      ],
    });
  }

  private setInitialState(): void {
    if (this.initialState) {
      this.form.patchValue({ address: this.initialState });
    }
  }

  private subscribeToFormChange(): void {
    this.form.statusChanges
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(
          (prev: string, curr: string) => prev === 'INVALID' && curr === 'INVALID',
        ),
      )
      .subscribe(() => {
        // eslint-disable-next-line max-len
        const newValue: { address: Dictionary | null } = this.form.valid ? { address: { ...this.form.value?.address, full_address: this.addressComponent.address.nativeElement.value } } : null;

        this.formComplete.emit(newValue);
      });
  }

}
