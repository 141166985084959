import type { NavItem } from '@core/types';

import { BILLING_PLAN, PERMITTED_ACTION, ROUTE, USER_ROLE } from '@consts/consts';

export const Dashboard: NavItem[] = [
  {
    label: 'Dashboard',
    routerLink: ROUTE.alias.DASHBOARD,
    counter: null,
    isNewCounterDot: false,
    permitted_actions: [PERMITTED_ACTION.DASHBOARD_VIEW],
    roles: [USER_ROLE.lender],
    exactRouteActiveMatch: true,
    icon: null,
  },
  {
    label: 'My Production',
    routerLink: ROUTE.alias.DASHBOARD_PRODUCTION,
    counter: null,
    isNewCounterDot: false,
    permitted_actions: [PERMITTED_ACTION.DASHBOARD_PRODUCTION_VIEW, PERMITTED_ACTION.INTELLIGENCE_CONNECT],
    roles: [USER_ROLE.lender, USER_ROLE.agent],
    restricted_plans: [BILLING_PLAN.LENDER_LIMITED_PLAN, BILLING_PLAN.TRIAL],
    exactRouteActiveMatch: false,
    icon: null,
  },
];
