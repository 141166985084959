<div class="auth__decor"><a class="auth__logo" [routerLink]="[ROUTE.alias.SIGN_IN]"></a></div>
<div class="auth__content d-flex flex-column justify-content-center">
  <form class="auth__form align-self-center"
        *ngIf="!showSuccessScreen"
        [formGroup]="form"
        (ngSubmit)="form.valid && restore(form)">
    <h1 class="auth__title">Forgot your password?</h1>
    <h5 class="auth__subtitle">Enter your email address below and we&rsquo;ll get you back on&nbsp;track.</h5>
    <iq-form-control-text class="m-b-2x"
                          label="Email"
                          type="email"
                          placeholder="myname@example.com"
                          [lpignore]="false"
                          formControlName="email"
                          [errorsModel]="formErrors.email"></iq-form-control-text>
    <div class="control-error-message" *ngFor="let error of formErrors.global">{{ error }}</div>
    <iq-button class="block"
               type="submit"
               label="Request reset link"
               size="56"
               [busy$]="action$"
               [disabled]="form.invalid"></iq-button>
    <div class="row">
      <div class="col text-center align-self-center"><a class="link" [routerLink]="[ROUTE.alias.SIGN_IN]">Back&nbsp;to
        Sign&nbsp;in</a></div>
    </div>
  </form>
  <div class="auth__form align-self-center" *ngIf="showSuccessScreen">
    <h1 class="auth__title">Password Reset Complete</h1>
    <h5 class="auth__subtitle">Password reset is&nbsp;complete, you&nbsp;should receive an&nbsp;email shortly with
      further instructions</h5>
    <div class="row">
      <div class="col text-center align-self-center">
        <div class="btn btn--outline-blue btn--block" [routerLink]="[ROUTE.alias.SIGN_IN]">Back&nbsp;to Sign&nbsp;in
        </div>
      </div>
    </div>
  </div>
</div>
