<div class="partner" (click)="openUserCard(modalType)">
  <avatar-placeholder class="size-48" [image]="partner?.avatar?.medium" [name]="partner?.name"></avatar-placeholder>
  <div class="partner__info">
    <div class="partner__info--name">{{ partner?.name }}</div>
    <div class="partner__info--company_name">{{ partner?.company_name }}</div>
  </div>
</div>
<div class="partner__action" [ngSwitch]="partner?.invitation_status">
  <ng-container *ngSwitchCase="invitationStates.active">
    <iq-button label="Invite" size="40" [promiseBtn]="action" (onClick)="invite()"></iq-button>
  </ng-container>
  <ng-container *ngSwitchCase="invitationStates.invitation_received">
    <iq-button class="m-r-10"
               label="Reject"
               color="grey"
               size="40"
               [style]="'outline'"
               (click)="declineInvite()"
               [promiseBtn]="action"></iq-button>
    <iq-button label="Accept" size="40" (click)="acceptInvite()" [promiseBtn]="action"></iq-button>
  </ng-container>
  <ng-container *ngSwitchDefault><span>{{ formatToUpper(partner?.invitation_status) }}</span></ng-container>
</div>
